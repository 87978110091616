import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SelectPicker from 'rsuite/SelectPicker';
import Layout from "../../layout/Layout";
import { getAffilationCityData, deleteAffilationCity, viewAffilationCity } from '../../service/apis';
import Swal from "sweetalert2";
import $ from "jquery";
import { AuthContext } from '../../AppContext';
import Fancybox from '../../Component/FancyBox';

const AffiliationCityView = () => {
    const { userPermission } = useContext(AuthContext)

    const params = useParams()
    const navigate = useNavigate()
    const [affilationCity, setAffilationCity] = useState({})
    const [allAffilationCityData, setAllAffilationCityData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [cityId, setCityId] = useState(params);
    const [selectedItem, setSelectedItem] = useState(params.id);

    useEffect(() => {
        // fetchAffilationCity()
        fetchAffilationCityDropdown()
    }, [])
    useEffect(() => {
        fetchAffilationCity(params)
        // fetchAffilationCityDropdown()
    }, [params])

    // city dropdown 
    const data = allAffilationCityData.map(
        item => ({ label: item.city, value: item.id })
    );
    //     //Apis
    const fetchAffilationCityDropdown = async (state, search) => {
        setIsLoading(true);
        try {
            const response = await getAffilationCityData()
            setAllAffilationCityData(response?.data?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const fetchAffilationCity = async (params) => {
        setIsLoading(true);
        try {
            const response = await viewAffilationCity(params.id)
            setAffilationCity(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    let count = 10
    let swalCountdownInterval
    const handleDelete = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    try {
                        deleteAffilationCity(params.id).then(() => navigate(`/affiliation-city`));

                    } catch (error) {
                        console.log(error)
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3><Link to="/affiliation-city" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Affiliation City View</h3>
                    <div className="page-heading-right">
                        <SelectPicker
                            cleanable={false}
                            data={data}
                            onChange={(e) => navigate(`/affiliation-city/view/${e}`)}
                            value={affilationCity?.id}
                            className="wv-200 my-1 ms-3"
                            placeholder="Select Place"
                            placement="bottomEnd"
                        />
                        {userPermission["Service-City"]?.edit == 1 && <Link to={`/affiliation-city/edit/${params.id}`} className="btn btn-primary ms-3 my-1" >Edit</Link>}
                        {userPermission["Service-City"]?.delete == 1 && <Button variant="danger ms-3 my-1 btn-icon-lg" onClick={handleDelete} type="button"><i className="bx bx-trash-alt"></i></Button>
                        }                    </div>
                </div>
                <div className='page-content'>
                    <Card>
                        <Card.Body>
                            <Row className="g-4">
                                <Col md={4}>
                                    <div>
                                        <p className='mb-0 fw-bold'>State</p><span>{affilationCity?.state}</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <p className='mb-0 fw-bold'>City</p><span>{affilationCity?.city}</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <p className='mb-0 fw-bold'>Image</p>
                                        <div>
                                            <Fancybox>
                                                <a href={affilationCity?.image} data-fancybox="gallery">
                                                    <img src={affilationCity?.image} className="hv-40 rounded-3" alt="" />
                                                </a>
                                            </Fancybox>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <p className='mb-0 fw-bold'>Is Popular</p><span>{affilationCity?.is_popular == 0 ? "No" : "Yes"}</span>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div>
                                        <p className='mb-0 fw-bold'>Pincode</p><span>
                                            {affilationCity?.pin_code_data?.map((pincode, i) => (
                                                <Badge key={i} bg="primary" className="mx-1 mb-1 wv-100">
                                                    {pincode.pincode}
                                                </Badge>
                                            ))}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default AffiliationCityView