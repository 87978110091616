import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import Fancybox from "../../Component/FancyBox";
import { API } from '../../App';
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import dayjs from 'dayjs'
import { AuthContext } from '../../AppContext';

var DropDownArr = []
var reloadId = [];
const VehicleInformationView = () => {
  const { userPermission, token } = useContext(AuthContext)
  const params = useParams();
  const [Modal_Color_Data, setModal_Color_Data] = useState([])
  const [Modal_Color_Image, setModal_Color_Image] = useState([])
  const [Price_variant_data, setPrice_variant_data] = useState([])
  const [DropDropValue, setDropDropValue] = useState([])
  const [id, setid] = useState({ _id: params.id });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const [Data, SetData] = useState(
    {
      _id: "",
      category_id: "",
      brand_id: "",
      body_id: "",
      name: "",
      fuel_type: "",
      rating: "",
      review: "",
      min_price: "",
      max_price: "",
      varient_name: "",
      price_range: "",
      status: "",
      image: "",
      thumb_image: "",
      home_image: "",
      launched_at: "",
      launched_date: "",
      popularity: "",
      mileage: "",
      engine: "",
      max_power: "",
      showroom_price: "",
      road_price: "",
      rto_price: "",
      insurance_price: "",
      other_price: "",
      upcoming: "",
      latest: "",
      manu_des: "",
      price_des: "",
      high_des: "",
      key_specs: "",
      seo_note: "",
      is_designer: "",
      is_content_writer: "",
      color_code: "",
      color_name: "",
      Vengine: "",
      Vex_show_room_rice: "",
      Vfuel_type: "",
      Vinsurance_price: "",
      Vis_scrapping: "",
      Vlatest_update: "",
      Vlaunched_at: "",
      Vvariant_image: "",
      Vmileage: "",
      Vname: "",
      Von_road_price: "",
      Vother_price: "",
      Vprice: "",
      Vprice_range: "",
      Vrating: "",
      Vreview_count: "",
      Vrto_price: "",
      Vstatus: ""

    });
  const Dropdown_Name = async () => {
    const Result = await API.get("/get_all_vehicle_information_model_name", { headers: { authorization: `${token}` } })
    DropDownArr = []
    reloadId = []
    // DropDownArr.push({label:"Select Title" , value:"" })
    Result.data.data.map((val, i) => {
      DropDownArr.push({ label: val.model_name, value: val.id })
      reloadId.push(val.id)
    })
    setid({ _id: params.id })
    setDropDropValue(DropDownArr)
  }

  const Getview = async (Eid) => {
    const result = await API.get(`/view_vehicle_information/${Eid !== "" ? Eid : id._id}`, { headers: { authorization: `${token}` } });
    console.log('result.data', result.data.data)

    SetData({
      ...Data,
      _id: result.data.data.id,
      category_id: result.data.data.CategoriesDeatils.category_name,
      brand_id: result.data.data?.BrandDeatils?.name,
      body_id: result?.data?.data?.body_type_data?.name,
      name: result.data.data.model_name,
      fuel_type: result.data.data.fuel_type,
      rating: result.data.data.rating,
      review: result.data.data.review_count,
      min_price: result.data.data.min_price,
      max_price: result.data.data.max_power,
      varient_name: result.data.data.variant_name,
      price_range: result.data.data.price_range,
      status: result.data.data.status,
      image: result.data.data.vehicle_information_images,
      thumb_image: result.data.data.image,
      home_image: result?.data?.data?.home_image,
      launched_at: dayjs(result.data.data.launched_at).format("DD-MM-YYYY"),
      launched_date: dayjs(result.data.data.Launch_date).format("DD-MM-YYYY"),
      popularity: result.data.data.model_popularity,
      mileage: result.data.data.mileage,
      engine: result.data.data.engine,
      max_power: result.data.data.max_power,
      showroom_price: result.data.data.showroom_price,
      road_price: result.data.data.on_road_price,
      rto_price: result.data.data.rto_price,
      insurance_price: result.data.data.insurance_price,
      other_price: result.data.data.other_price,
      upcoming: result.data.data.is_upcoming,
      latest: result.data.data.is_latest,
      manu_des: result.data.data.manufaturer_desc,
      price_des: result.data.data.price_desc,
      high_des: result.data.data.high_des,
      key_specs: result.data.data.key_specs,
      seo_note: result.data.data.seo_note,
      Cate_id: result.data.data.category_id.id,
      brn_id: result.data.data.brand_id.id,
      bod_id: result.data.data.body_id?.id,
      is_designer: result.data.data.is_designer,
      is_content_writer: result.data.data.is_content_writer,
    })
    setModal_Color_Data(result.data.data.vehicles_model_color)
    setPrice_variant_data(result.data.data.vehicle_price_variant)
  };

  const selectpickerData = (e) => {
    console.log("=e", e);
    setid({ _id: e });
    Getview(e);
  };

  useEffect(() => {
    Dropdown_Name()
    Getview("")
  }, [])


  let count = 10
  let swalCountdownInterval
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data._id)
          reloadId.splice(ind, 1)
          setIsLoading(true);
          const _result = await API.delete(`/delete_vehicle/${Data._id}`, { headers: { authorization: `${token}` } });
          if (_result) {
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
          navigate('/vehicle-information')
          // const formdata = new FormData()
          // formdata.append("id", Data._id)
          // const result = await API.post("/delete/vehicleinfo", formdata, { headers: { authorization: `${token}` } });
          // if (reloadId.length === 0) {
          //   window.location.replace(`http://localhost:3000/Vehicale_information`)
          //   // window.location.replace(`${process.env.REACT_APP_BASE_URL}Vehicale_information`)
          // } else {
          //   window.location.replace(`http://localhost:3000/view/Vehicale_information/${reloadId[0]}`)
          //   // window.location.replace(`${process.env.REACT_APP_BASE_URL}view/Vehicale_information/${reloadId[0]}`)
          // }
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };
  return (
    <Layout sidebar={true}>
      {isLoading && <div className="loader" ></div>}
      <div className="page-heading">
        <h3><Link to="/vehicle-information" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Vehicle Information View</h3>
        <div className="page-heading-right">
          <SelectPicker data={DropDropValue} value={Number(id._id)} cleanable={false} className="wv-200 my-1 ms-3" onChange={(e) => selectpickerData(e)} placeholder="Select Model Name" placement="bottomEnd" />
          {userPermission["Vehicle-info2-Information"]?.edit == 1 && <Link to={`/vehicle-information/edit/${id._id}`}>
            {/* <Link to={`/Edit/Vehicale_information/${id._id}`}> */}

            <Button variant="primary ms-3 my-1" valsue="edit">Edit</Button>
          </Link>}
          {userPermission["Vehicle-info2-Information"]?.delete == 1 && <Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt"></i></Button>
          } </div>
      </div>
      <div className='page-content'>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Category</p><span>{Data.category_id}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Brand</p><span>{Data.brand_id}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Body Type</p><span>{Data.body_id}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Model Name</p><span>{Data.name}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Fuel Type</p><span>{Data.fuel_type}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Avg Rating</p><span>{Data.rating}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Review Count</p><span>{Data.review}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Minimum Price</p><span>{Data.min_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Maximum Price</p><span>{Data.max_price}</span>
                </div>
              </Col>
              {/* <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Body Type</p><span>{Data.body_id}</span>
                </div>
              </Col> */}
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Variant Name</p><span>{Data.varient_name}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Price Range</p><span>{Data.price_range}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Status</p><span>{Data.status}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Image</p>
                  <Fancybox>

                    {Data?.image?.length > 0 && Data?.image?.map((item) => {
                      return (<a data-fancybox="gallery" >
                        <img
                          src={item.vehicle_information_image ? item.vehicle_information_image : "/../avatar/dummy-img.svg"}
                          onError={(e) => {
                            e.target.src = '/../avatar/dummy-img.svg';
                          }}
                          className="hv-40 rounded-3"
                          alt="Image"
                        />
                      </a>)
                    })}
                  </Fancybox>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Thumb Image</p>
                  <Fancybox>
                    <a data-fancybox="gallery" href={Data.thumb_image}>
                      <img
                        src={Data.thumb_image ? Data.thumb_image : "/../avatar/dummy-img.svg"}
                        onError={(e) => {
                          e.target.src = '/../avatar/dummy-img.svg';
                        }}
                        className="hv-40 rounded-3"
                        alt="Image"
                      />
                    </a>
                  </Fancybox>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Home Image</p>
                  {console.log(Data)}
                  <Fancybox>
                    <a data-fancybox="gallery" href={Data.home_image}>
                      <img
                        src={Data.home_image ? Data.home_image : "/../avatar/dummy-img.svg"}
                        onError={(e) => {
                          e.target.src = '/../avatar/dummy-img.svg';
                        }}
                        className="hv-40 rounded-3"
                        alt="Image"
                      />
                    </a>
                  </Fancybox>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Launched At</p><span>{Data.launched_at}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Launched Date</p><span>{Data.launched_date}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Model Popularity</p><span>{Data.popularity}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Mileage</p><span>{Data.mileage}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Engine</p><span>{Data.engine}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Max Power</p><span>{Data.max_power}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Showroom Price</p><span>{Data.showroom_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>On Road Price</p><span>{Data.road_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>RTO Price</p><span>{Data.rto_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Insurance Price</p><span>{Data.insurance_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Other Price</p><span>{Data.other_price}</span>
                </div>
              </Col>

              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Is Upcoming</p><span>{Data.upcoming == 1 ? "On" : "Off"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Is Latest</p><span>{Data.latest == 1 ? "On" : "Off"}</span>
                </div>
              </Col>

              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Is Designer</p><span>{Data.is_designer == 1 ? "On" : "Off"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Is content writer</p><span>{Data.is_content_writer == 1 ? "On" : "Off"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {
          Modal_Color_Data?.length > 0 && (
            <div className='page-content mt-4'>
              <Card>
                <Card.Body>
                  <Row>
                    {/* <Col md={12} className='text-end'>
                        <Button variant='primary' size='sm'>Variant Image Download All</Button>            
                      </Col> */}
                    {
                      Modal_Color_Data?.length > 0 && Modal_Color_Data?.map((val, i) => {
                        return (
                          <>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Color Name</p><span>{val.color_name}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Color Code</p><span>{val.color_code}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-4">
                                <p className="mb-1 fw-bold">Variant Image</p>
                                {
                                  val?.vehicle_model_color_images?.map((Data) => {
                                    return (
                                      <Fancybox>
                                        <a data-fancybox="gallery" href={Data.vehicle_model_color_image}>
                                          <img
                                            src={Data.vehicle_model_color_image}
                                            className="hv-40 rounded-3 me-2"
                                            alt="Image"
                                          />
                                        </a>
                                      </Fancybox>
                                    )
                                  })
                                }
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </Row>
                </Card.Body>
              </Card>
            </div>
          )
        }

        {
          Price_variant_data?.length > 0 && (

            <div className='page-content mt-4'>
              <Card>
                <Card.Body>
                  <Row>
                    {
                      Price_variant_data?.map((val) => {
                        return (
                          <>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Name</p><span>{val.name}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Engine</p><span>{val.engine}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Price</p><span>{val.price}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Price Range</p><span>{val.price_range}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Review Count</p><span>{val.review_count}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Rating</p><span>{val.rating}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Status</p><span>{val.status}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Fuel Type</p><span>{val.fuel_type}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Ex Show Room Price</p><span>{val.ex_show_room_rice}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Mileage</p><span>{val.mileage}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>RTO Price</p><span>{val.rto_price}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Insurance Price</p><span>{val.insurance_price}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Other Price</p><span>{val.other_price}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>On-Road Price</p><span>{val.on_road_price}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Latest Update</p><span>{val.latest_update}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='mb-4'>
                                <p className='mb-0 fw-bold'>Launched At</p><span>{val.launched_at}</span>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-4">
                                <p className="mb-1 fw-bold">Variant Image</p>
                                <Fancybox>
                                  <a data-fancybox="gallery" href={val.image}>
                                    <img
                                      src={val.image}
                                      className="hv-40 rounded-3"
                                      alt="Image"
                                    />
                                  </a>
                                </Fancybox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </Row>
                </Card.Body>
              </Card>
            </div>
          )}
      </div>
    </Layout>
  )
}

export default VehicleInformationView