import React, { useState } from "react";
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import { API } from "../../App";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { SelectPicker } from "rsuite";

var StateNAmeArray = []
const TrafficRuleAdd = () => {
  const token = Cookies.get("fmljwt");
  const params = useParams()
  const [validated, setvalidated] = useState(false)
  const [Sate_nameData, setSate_nameData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState({
    traffic_state_id: "",
    offence: "",
    penalty: "",
  })

  const navigate = useNavigate()

  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value })
  }

  const Getview = async () => {
    setIsLoading(true)
    if (params.id) {
      try {
        const result = await API.post(`/edit_traffic_rules/${params.id}`, {}, { headers: { authorization: `${token}` } });
        setData({
          traffic_state_id: result.data.data.traffic_state_details.id,
          offence: result.data.data.offence,
          penalty: result.data.data.penalty,
        });
        setIsLoading(false)
      } catch (error) {
        toast(error.message)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    } else {
      setData({
        traffic_state_id: "",
        offence: "",
        penalty: "",
      });
      setIsLoading(false)
    }
  };

  const State_name_dropdown = async () => {
    const Result = await API.post("/get_traffic_states_dropdown", {}, { headers: { authorization: `${token}` } })
    StateNAmeArray = []
    StateNAmeArray.push({ label: "Select State", value: "", role: "" })
    Result.data.data.map((val) => {
      StateNAmeArray.push({ label: `${val.state_code + "-" + val.state_name}`, value: val.id })
    })
    setSate_nameData(StateNAmeArray)
  }

  const State_nameHendler = async (e, name) => {
    setData({ ...Data, [name]: e });
  };


  useEffect(() => {
    State_name_dropdown()
    Getview()
  }, [])

  const Submite = async () => {
    if (Data.offence == "" || Data.penalty == "") {
      setvalidated(true)
    } else {
      setIsLoading(true)
      if (params.id) {
        try {
          const Result = await API.post(`/update_traffic_rules/${params.id}`, Data, { headers: { authorization: `${token}` } })
          if (Result) {
            toast.success("Data Update successfully");
            navigate(`/traffic-rule/view/${params.id}`)
          }
        } catch (error) {
          toast(error.message)
          setIsLoading(false)
        } finally {
          setIsLoading(false)
        }
      } else {
        try {
          const Result = await API.post("/storetrafficstate", Data, { headers: { authorization: `${token}` } })
          toast.success("Data Saved successfully");
          navigate("/traffic-rule")
        } catch (error) {
          toast(error.message)
        } finally {
          setIsLoading(false)
        }
      }
    }
  }
  return (
    <Layout sidebar={true}>
      {isLoading && <div className="loader" ></div>}
      <div className="page-heading">
        <h3>Traffic Rule {params.id ? "Edit" : "Add"}</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/traffic-rule">Traffic Rule</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Traffic Rule {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validated}>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Form.Label htmlFor="statename">State Name</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={Sate_nameData}
                    value={Data.traffic_state_id}
                    className="my-2"
                    placeholder="Select State"
                    block
                    onChange={(e) => State_nameHendler(e, "traffic_state_id")}
                  />
                </Col>
                <Col md={4}>
                  <Form.Label htmlFor="offence">Offence</Form.Label>
                  <Form.Control
                    type="text"
                    name="offence"
                    className="my-2"
                    value={Data.offence}
                    onChange={SaveData}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Offence Field Is Require
                  </Form.Control.Feedback>
                </Col>

                <Col md={4}>
                  <Form.Label htmlFor="penalty">Penalty</Form.Label>
                  <Form.Control
                    type="text"
                    name="penalty"
                    className="my-2"
                    value={Data.penalty}
                    onChange={SaveData}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Penalty Field Is Require
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" onClick={Submite} className="me-3">Save</Button>
              <Link to={params.id ? `/traffic-rule/view/${params.id}` : `/traffic-rule`}>
                <Button variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  )
}

export default TrafficRuleAdd