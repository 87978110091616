import React, { useState } from "react";
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { API } from "../../App";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { SelectPicker } from "rsuite";

var Category_Array = []
var Brand_Array = []
var Vehicale_name = []
var Variant_name = []
var Specificaton_Data = []
var Key_Specificaton_Data = []
const VehicleVariantSpecificationEdit = () => {
  const params = useParams()
  const token = Cookies.get("fmljwt");
  const [Category_Name, setCategory_Name] = useState([])
  const [Brand_Name, setBrand_Name] = useState([])
  const [Vehicle__Name, setVehicle__Name] = useState([])
  const [Variant_Name, setVariant_Name] = useState([])
  const [setParamId, setSetParamId] = useState(params.id)
  const [validated, setvalidated] = useState(false)
  const navigate = useNavigate()
  const [Data, setData] = useState({
    category_id: "",
    brand_id: "",
    vehicle_id: "",
    Variant_id: "",
  })
  const [isLoading, setIsLoading] = useState(false);

  const [SpecificationDATA, setSpecificationDATA] = useState([])
  const [Key_SpecificationDATA, setKey_SpecificationDATA] = useState([])
  const [AddMoreSpecification, setAddMoreSpecification] = useState([{
    specification: "",
    specification_name: "",
    specification_value: "",
    key_specification: "",
    is_feature: 0,
    is_specification: 0,
    is_overview: 0,
  }])
  const [deletionIdsArray, setDeletionIdsArray] = useState([])

  const GetData = async () => {
    if (setParamId || Data.Variant_id) {
      const result = await API.post(`/get_edit_detsils/${setParamId}`, {}, { headers: { authorization: `${token}` } });
      if (result) {
        setData({
          category_id: result?.data?.data?.priceVariantDetsils?.VehicleInformationDetails?.CategoriesDeatils?.id,
          brand_id: result?.data?.data?.priceVariantDetsils?.VehicleInformationDetails?.BrandDeatils?.id,
          vehicle_id: result?.data?.data?.priceVariantDetsils?.VehicleInformationDetails?.id,
          Variant_id: result?.data?.data?.priceVariantDetsils?.id,
        })
      }
      setAddMoreSpecification(result?.data?.data?.specificationDetails?.map(items => {
        return {
          id: items.id,
          specification: items?.specification_id,
          specification_name: items?.name,
          specification_value: items?.value,
          key_specification: items?.variant_key_id,
          is_feature: items?.is_feature,
          is_specification: items?.is_specification,
          is_overview: items?.show_overview,
        }
      }))
    } else {
      setData({
        category_id: "",
        brand_id: "",
        vehicle_id: "",
        Variant_id: ""
      })
    }

  }
  const [ShowColorModal, setShowColorModal] = useState(false)

  const addspecificationRow = async () => {
    setShowColorModal(true)
    setvalidated(false)
    // if (ShowColorModal == true) {
    setAddMoreSpecification([
      ...AddMoreSpecification,
      {
        id: "",
        specification: "",
        specification_name: "",
        specification_value: "",
        key_specification: "",
        is_feature: 0,
        is_specification: 0,
        is_overview: 0,
      }
    ])
    // }
  }

  const removespecificationRow = async (i, d) => {


    if (i == undefined) {
      let SpcificatonDatas = [...AddMoreSpecification]
      SpcificatonDatas.splice(d, 1)
      setAddMoreSpecification(SpcificatonDatas)
    } else {
      setDeletionIdsArray([...deletionIdsArray, i])
      let SpcificatonDatas = [...AddMoreSpecification]
      SpcificatonDatas.splice(d, 1)
      setAddMoreSpecification(SpcificatonDatas)
    }

  }

  const Specification_dropdown = async () => {
    const resut = await API.post("/get_specification_dropdown", {}, { headers: { authorization: `${token}` } });
    Specificaton_Data = []
    Specificaton_Data.push({ label: "Select Specififcation", value: "" })
    resut.data.data.map((val, index) => {
      Specificaton_Data.push({ label: val.name, value: val.id })
    })
    setSpecificationDATA(Specificaton_Data)
  }

  const Specificaton_Hendler = async (e, index, name) => {
    setvalidated(false)
    const updatedRows = [...AddMoreSpecification];
    updatedRows[index][name] = e;
    setAddMoreSpecification(updatedRows);
  };

  const Key_Specification_dropdown = async () => {
    const resut = await API.post("/get_key_specification_dropdown", {}, { headers: { authorization: `${token}` } });
    Key_Specificaton_Data = []
    Key_Specificaton_Data.push({ label: "Select Key Specififcation", value: "" })
    resut.data.data.map((val, index) => {
      Key_Specificaton_Data.push({ label: val.name, value: val.id })
    })
    setKey_SpecificationDATA(Key_Specificaton_Data)
  }

  const Key_Specificaton_Hendler = async (e, index, name) => {
    const updatedRows = [...AddMoreSpecification];
    updatedRows[index][name] = e;
    setAddMoreSpecification(updatedRows);
  };


  const Category_dropdown = async () => {
    const resut = await API.post("/get_category_dropdown", {}, { headers: { authorization: `${token}` } });
    Category_Array = []
    Category_Array.push({ label: "Select Category", value: "" })
    resut.data.data.map((val, index) => {
      Category_Array.push({ label: val.category_name, value: val.id })
    })
    setCategory_Name(Category_Array)
  }

  const Brand_dropdown = async (e) => {
    const Form = new FormData()
    Form.append("id", e)
    const resut = await API.post("/get_brand_dropdown", Form, { headers: { authorization: `${token}` } });
    Brand_Array = []
    Brand_Array.push({ label: "Select Brand", value: "" })
    resut.data.data.map((val, index) => {
      Brand_Array.push({ label: val.name, value: val.id })
    })
    setBrand_Name(Brand_Array)
  }

  const Vehicle_dropdown = async (e) => {
    const Form = new FormData()
    Form.append("brand_id", e)
    const resut = await API.post("/get_vehicleinfo_dropdown", Form, { headers: { authorization: `${token}` } });
    Vehicale_name = []
    console.log('resut', resut)
    Vehicale_name.push({ label: "Select Vehicle Name", value: "" })
    resut.data.data.map((val, index) => {
      Vehicale_name.push({ label: val.model_name, value: val.id })
    })
    setVehicle__Name(Vehicale_name)
  }

  const Variant_dropdown = async (e) => {
    const Form = new FormData()
    Form.append("vehicle_information_id", e)
    const resut = await API.post("/get_pricevariant_dropdown", Form, { headers: { authorization: `${token}` } });
    Variant_name = []
    Variant_name.push({ label: "Select Variant Name", value: "" })
    resut.data.data.map((val, index) => {
      Variant_name.push({ label: val.name, value: val.id })
    })
    setVariant_Name(Variant_name)
  }

  const Category_Hendler = async (e, name) => {
    setData({ ...Data, [name]: e, Variant_id: "", vehicle_id: "", brand_id: "" });
    Brand_dropdown(e)
    setAddMoreSpecification([])
  };

  const Brand_Hendler = async (e, name) => {
    setData({ ...Data, [name]: e, Variant_id: "", vehicle_id: "" });
    Vehicle_dropdown(e)
    setAddMoreSpecification([])
  };

  const Vehicle_Hendler = async (e, name) => {
    setData({ ...Data, [name]: e, Variant_id: "" });
    Variant_dropdown(e)
    setAddMoreSpecification([])
  };

  const Variant_Hendler = async (e, name) => {
    setSetParamId(e)
    setData({ ...Data, [name]: e });
    e ? navigate(`/vehicle-variant-specification/edit/${e}`) : setAddMoreSpecification()
    await GetSpecification(e)
  };

  const GetSpecification = async (e) => {
    const Result = await API.post(`/get_edit_detsils/${e}`, {}, { headers: { authorization: `${token}` } })
    setAddMoreSpecification(Result?.data?.data?.specificationDetails?.map(items => {
      return {
        id: items.id,
        specification: items?.specification_id,
        specification_name: items?.name,
        specification_value: items?.value,
        key_specification: items?.variant_key_id,
        is_feature: items?.is_feature,
        is_specification: items?.is_specification,
        is_overview: items?.show_overview,
      }
    }))
  }

  const SaveData = (e, index) => {
    setvalidated(false)
    const { name, value } = e.target;
    const updatedRows = [...AddMoreSpecification];
    updatedRows[index][name] = value;
    setAddMoreSpecification(updatedRows);
  }

  const returnStatus = (e, index, name) => {
    setvalidated(false)
    let data = [...AddMoreSpecification];
    if (name == "is_feature") {
      data[index]['is_feature'] = e == true ? 1 : 0
      setAddMoreSpecification(data)
    }
    if (name == "is_specification") {
      data[index]['is_specification'] = e == true ? 1 : 0
      setAddMoreSpecification(data)
    }
    if (name == "is_overview") {
      data[index]['is_overview'] = e == true ? 1 : 0
      setAddMoreSpecification(data)
    }
  }

  useEffect(() => {
    Category_dropdown()
    Specification_dropdown()
    Key_Specification_dropdown()
    GetData()
  }, [])

  const Submite = async () => {
    setIsLoading(true)
    try {
      let count = 0
      AddMoreSpecification.map((vals, ind) => {
        if (vals.specification_name == "" || vals.specification_value == "") {
          count++
        }
      })
      if (count !== 0) {
        setvalidated(true)
      } else {
        const Form = new FormData()
        Form.append("category_id", Data.category_id)
        Form.append("brand_id", Data.brand_id)
        Form.append("vehicle_id", Data.vehicle_id)
        Form.append("Variant_id", Data.Variant_id)
        // AddMoreSpecification.forEach((row, index) => {
        //   Form.append(`_ID-${index}`, row.id);
        //   Form.append(`specification-${index}`, row.specification);
        //   Form.append(`key_specification-${index}`, row.key_specification);
        //   Form.append(`specification_name-${index}`, row.specification_name);
        //   Form.append(`specification_value-${index}`, row.specification_value);
        //   Form.append(`is_feature-${index}`, row.is_feature);
        //   Form.append(`is_overview-${index}`, row.is_overview);
        //   Form.append(`is_specification-${index}`, row.is_specification);
        // });
        Form.append('SpecificationData', JSON.stringify(AddMoreSpecification))
        Form.append('sepecificationRemoveData', JSON.stringify(deletionIdsArray))

        const result = await API.post(`/edit_variant_details/${params.id}`, Form,{ headers: { authorization: `${token}` } })
        if (result) {
          toast.success("Data Update Successfuly")
          navigate("/vehicle-variant-specification")
        }
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }

  }

  useEffect(() => {
    if (Data.category_id != undefined || Data.brand_id != undefined || Data.vehicle_id || Data.Variant_id) {
      Brand_dropdown(Data.category_id)
      Vehicle_dropdown(Data.brand_id)
      Variant_dropdown(Data.vehicle_id)
    }
  }, [Data.category_id, Data.brand_id, Data.vehicle_id, Data.Variant_id])

  return (
    <Layout sidebar={true}>
      {isLoading && <div className="loader" ></div>}
      <div className="page-heading">
        <h3>Variant Specification Edit</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/vehicle-variant-specification">Variant Specification</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Variant Specification Edit</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validated}>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={3}>
                  <Form.Label htmlFor="category">Category</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={Category_Name}
                    name="category_id  "
                    value={Data?.category_id}
                    className="my-2"
                    block
                    placeholder="Select Category"
                    onChange={(e) => Category_Hendler(e, "category_id")}
                  />
                </Col>
                <Col md={3}>
                  <Form.Label htmlFor="brand">Brand</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={Brand_Name}
                    name="brand_id"
                    className="my-2"
                    value={Data?.brand_id}
                    block
                    placeholder="Select Brand"
                    onChange={(e) => Brand_Hendler(e, "brand_id")}
                  />
                </Col>
                <Col md={3}>
                  <Form.Label htmlFor="vehiclename">Vehicle Name</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={Vehicle__Name}
                    name="vehicle_id"
                    className="my-2"
                    value={Data?.vehicle_id}
                    block
                    placeholder="Select Vehicle Name"
                    onChange={(e) => Vehicle_Hendler(e, "vehicle_id")}
                  />
                </Col>
                <Col md={3}>
                  <Form.Label htmlFor="vehiclevariant">Vehicle Variant</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={Variant_Name}
                    name="Variant_id  "
                    value={Data?.Variant_id}
                    className="my-2"
                    block
                    placeholder="Select Vehicle Variant"
                    onChange={(e) => Variant_Hendler(e, "Variant_id")}
                  />
                </Col>
              </Row>
              {
                AddMoreSpecification?.length > 0 ?
                  <Row>
                    <Col>
                      <Button
                        variant="primary"
                        className="my-2"
                        onClick={addspecificationRow}
                      >
                        Add More Specification
                      </Button>
                    </Col>
                  </Row>
                  : ""
              }
              {
                // ShowColorModal == true &&
                AddMoreSpecification?.length > 0 ?
                  AddMoreSpecification?.map((val, i) => (
                    <Row>
                      <Col md={3}>
                        <Form.Label htmlFor="specification">Specification</Form.Label>
                        <SelectPicker
                          cleanable={false}
                          data={SpecificationDATA}
                          name="specification"
                          value={val.specification}
                          className="my-2"
                          block
                          placeholder="Select Specification"
                          onChange={(e) => Specificaton_Hendler(e, i, "specification")}
                        />
                      </Col>

                      <Col md={3}>
                        <Form.Label htmlFor="specificationname">Specification Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="my-2"
                          name="specification_name"
                          value={val.specification_name}
                          onChange={(e) => SaveData(e, i)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Specification Name Field Is Require
                        </Form.Control.Feedback>
                      </Col>

                      <Col md={3}>
                        <Form.Label htmlFor="specificationvalue">Specification Value</Form.Label>
                        <Form.Control
                          type="text"
                          className="my-2"
                          name="specification_value"
                          value={val.specification_value}
                          onChange={(e) => SaveData(e, i)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Specification Value Field Is Require
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={1}>
                        <Form.Label htmlFor="isfeature">Is Feature</Form.Label>
                        <Switch
                          offColor="#C8C8C8"
                          onColor="#0093ed"
                          checked={val.is_feature}
                          onChange={(e) => returnStatus(e, i, "is_feature")}
                          height={30}
                          width={70}
                          name="is_feature"
                          className="react-switch my-2"
                          uncheckedIcon={
                            <div className="react-switch-off">OFF</div>
                          }
                          checkedIcon={
                            <div className="react-switch-on">ON</div>
                          }
                        />
                      </Col>
                      <Col md={1}>
                        <Form.Label htmlFor="isspecification">Is Specification</Form.Label>
                        <Switch
                          offColor="#C8C8C8"
                          onColor="#0093ed"
                          checked={val.is_specification}
                          onChange={(e) => returnStatus(e, i, "is_specification")}
                          height={30}
                          width={70}
                          name="is_specification"
                          className="react-switch my-2"
                          uncheckedIcon={
                            <div className="react-switch-off">OFF</div>
                          }
                          checkedIcon={
                            <div className="react-switch-on">ON</div>
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Form.Label htmlFor="isoverview">Is Overview</Form.Label>
                        <Switch
                          offColor="#C8C8C8"
                          onColor="#0093ed"
                          checked={val.is_overview}
                          onChange={(e) => returnStatus(e, i, "is_overview")}
                          height={30}
                          width={70}
                          name="is_overview"
                          className="react-switch my-2"
                          uncheckedIcon={
                            <div className="react-switch-off">OFF</div>
                          }
                          checkedIcon={
                            <div className="react-switch-on">ON</div>
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <Form.Label htmlFor="keyspecification">Key Specification</Form.Label>
                        <SelectPicker
                          cleanable={false}
                          data={Key_SpecificationDATA}
                          name="key_specification"
                          value={val.key_specification}
                          className="my-2"
                          block
                          placeholder="Select Key Specification"
                          onChange={(e) => Key_Specificaton_Hendler(e, i, "key_specification")}
                        />
                      </Col>

                      <Col md={3} className="d-flex align-self-end mb-2">
                        <Button
                          variant="danger"
                          className="btn-icon-lg"
                          onClick={() => removespecificationRow(val.id, i)}
                        >
                          <i className='bx bx-minus' ></i>
                        </Button>
                      </Col>
                    </Row>
                  ))
                  : ""
              }
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" onClick={Submite} className="me-3">Save</Button>
              <Link to={`/vehicle-variant-specification/view/${params.id}`} >
                <Button variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout >
  )
}

export default VehicleVariantSpecificationEdit