import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Card, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import Fancybox from "../../Component/FancyBox";
import { API } from "../../App";
import $ from "jquery";
import Swal from "sweetalert2";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import { AuthContext } from "../../AppContext";

var Page_array = [];
const VehicleKeySpecification = () => {
    const { userPermission, token } = useContext(AuthContext)
    const [Data, setData] = useState([])
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);
    const [PageHook, setPageHook] = useState([])
    const [loading, setloading] = useState(true)
    const GetData = async () => {
        const result = await API.post("/get_all_keyspecification", {}, { headers: { authorization: `${token}` } })
        setData(result.data.data)
        PageGetData()
        setloading(false)
    }

    // Paggintion Code //
    const getData1 = (current, pageSize) => {
        return Data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(Data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    };

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const PrevNextArrow = (current, type, originalElement) => {
        if (type === "prev") {
            return <button className="paggination-btn">Previous</button>;
        }
        if (type === "next") {
            return <button className="paggination-btn">Next</button>;
        }
        return originalElement;
    };

    const PageGetData = async () => {
        var PageNumber = [10, 25, 50, 100]
        Page_array = []
        PageNumber.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };

    let count = 10
    let swalCountdownInterval
    const DeleteData = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
          const result = await API.post(`/deletekeyinfo/${id}`, {}, { headers: { authorization: `${token}` } });

                    GetData();
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    useEffect(() => {
        GetData()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Key Specification</h3>
                <div className="page-heading-right">
                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        defaultValue={10}
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                    {userPermission["Vehicle-info2-Key-Specification"]?.add==1&&<Link to="/vehicle-key-specification/add" className="my-1 ms-3">
                        <Button variant="primary" value="create">Add New</Button>
                    </Link>}
                </div>
            </div>
            <div className="page-content">
                <Card>
                    <Card.Body>
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className='text-center'>No</th>
                                    <th width="75%">Name</th>
                                    <th width="10%" className="text-center">Icon</th>
                                    <th width="10%" className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getData1(current, size).map((val, i) => {
                                        return (
                                            <tr>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td>{val.name}</td>
                                                <td className="text-center">
                                                    <Fancybox>
                                                        <a href={val.icon} data-fancybox="gallery">
                                                            <img src={val.icon?val.icon:"./avatar/dummy-img.svg"} 
                                                            onError={(e) => {
                                                                e.target.src = './avatar/dummy-img.svg'; 
                                                              }} className="hv-40 rounded-3" alt="" />
                                                        </a>
                                                    </Fancybox>
                                                </td>
                                                <td className='text-center'>
                                                    <Link to={`/vehicle-key-specification/view/${val.id}`}>
                                                        <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                    </Link>
                                                    {userPermission["Vehicle-info2-Key-Specification"]?.delete==1&&<Button variant="outline-danger" onClick={() => DeleteData(val.id)} size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                                                }</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            {
                                loading == false && Data.length === 0 ? <tr>
                                    <td colSpan="100%" className="p-0">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found Key Specification</p>
                                        </div>
                                    </td>
                                </tr> : ""
                            }
                        </Table>
                        {Data.length > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={Data.length}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                    itemRender={PrevNextArrow}
                                    onShowSizeChange={PerPageChange}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
  )
}

export default VehicleKeySpecification