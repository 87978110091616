import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams} from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { API } from '../../App';
import $, { data } from "jquery";
import Swal from "sweetalert2";
import { AuthContext } from '../../AppContext';

const VehicleCompareView = () => {
  const { userPermission, token } = useContext(AuthContext)
  const params=useParams()
  const navigate=useNavigate()
  const [compareData, setCompareData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getCompareData()
  }, [])

  const getCompareData = async () => {
    setIsLoading(true);
    try {
      const response = await API.post(`/get_single_comparedata/${params.id}`,{},{ headers: { authorization: `${token}` } })
      setCompareData(response?.data?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }
  let count = 10
  let swalCountdownInterval
  const DeleteData = async (id) => {
      const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
              confirmButton: "btn btn-danger btn-lg counter",
              cancelButton: "btn btn-primary btn-lg me-3",
          },
          buttonsStyling: false,
      });

      swalWithBootstrapButtons
          .fire({
              title: "Are you sure you want to delete?",
              text: "You won't be able to revert this!",
              imageUrl: '../../icon/alert.svg',
              imageWidth: 80,
              imageHeight: 80,
              confirmButtonText: "OK (10)",
              cancelButtonText: "Cancel",
              showCancelButton: true,
              reverseButtons: true,
              didOpen: () => {
                  $(".swal2-confirm").attr('disabled', true);
                  swalCountdownInterval = setInterval(function () {
                      count--
                      if (count < 1) {
                          $(".counter").text(`OK`)
                          $(".swal2-confirm").attr('disabled', false);
                          clearInterval(swalCountdownInterval)
                      } else {
                          $(".counter").text(`OK (${count})`)
                      }
                  }, 1000);
              }
          })
          .then(async (result) => {
              if (result.isConfirmed) {
                  await API.post(`/delete_compareData/${params.id}`, {},{ headers: { authorization: `${token}` } });
                  navigate("/vehicle-compare")
              } else {
                  count = 10
                  clearInterval(swalCountdownInterval)
              }
          });
  };

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3><Link to="/vehicle-compare" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Vehicle Compare View</h3>
        <div className="page-heading-right">
          {userPermission["Vehicle-info2-Compare"]?.edit==1&&<Link to={`/vehicle-compare/edit/${params?.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
          </Link>}
          {userPermission["Vehicle-info2-Compare"]?.delete==1&&<Button variant="danger ms-3 my-1 btn-icon-lg" onClick={DeleteData} type="button"><i className="bx bx-trash-alt"></i></Button>
       } </div>
      </div>
      <div className='page-content'>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Category</p><span>{compareData?.categoryName?.category_name}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Vehicle 1</p><span>{compareData?.vehicle1_name?.model_name}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Vehicle 2</p><span>{compareData?.vehicle2_name?.model_name}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Status</p><span>{compareData?.status==1?"On":"Off"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default VehicleCompareView