import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from "../../layout/Layout";
import { SelectPicker } from "rsuite";
import { API } from "../../App";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { AuthContext } from "../../AppContext";
import Select from "react-select";
import { FixedSizeList } from "rsuite/esm/Windowing";

var DropDownArr = [];
var reloadId = [];
const DrivingSchoolDetailsView = () => {
  const { userPermission, token } = useContext(AuthContext)
  const navigate = useNavigate();
  const params = useParams();
  const [id, setid] = useState({ id: params.id });
  const [isLoading, setIsLoading] = useState(false);

  const [Data, SetData] = useState({
    id: "",
    city_id: "",
    zip_code: "",
    name: "",
    address: "",
    contact_name: "",
    type: "",
    latitude: "",
    longitude: "",
    open_Time: "",
    close_Time: "",
    close_Days: "",
    contactNumber1: "",
    contactNumber2: "",
    establishedYear: "",
    email: "",
    website: "",
    services: "",
    sun: "",
    mon: "",
    tue: "",
    wed: "",
    thu: "",
    fri: "",
    sat: "",
    S_id: "",
  });
  const [options, setOptions] = useState([]);

  const Dropdown_Name = async () => {
    const Result = await API.post(
      "/get_all_details",
      {},
      { headers: { authorization: `${token}` } }
    );
    DropDownArr = [];
    reloadId = [];
    Result.data.data.map((val, i) => {
      DropDownArr.push({ label: val.name, value: val.id });
      reloadId.push(val.id);
    });
    setOptions(DropDownArr);
  };

  const Getview = async (Eid) => {
    // setIsLoading(true);
    try {
      const result = await API.post(
        `/edit_driving_school_detail/${Eid !== "" ? Eid : id.id}`,
        {},
        { headers: { authorization: `${token}` } }
      );
      SetData({
        id: result.data.data.id,
        city_id: result.data.data.cityDetail.city_name,
        zip_code: result.data.data.zip_code,
        name: result.data.data.name,
        address: result.data.data.address,
        contact_name: result.data.data.contact_name,
        type: result.data.data.type,
        latitude: result.data.data.latitude,
        longitude: result.data.data.longitude,
        open_Time: result.data.data.open_Time,
        close_Time: result.data.data.close_Time,
        close_Days: result.data.data.close_Days,
        contactNumber1: result.data.data.contactNumber1,
        contactNumber2: result.data.data.contactNumber2,
        establishedYear: result.data.data.establishedYear,
        email: result.data.data.email,
        website: result.data.data.website,
        services: result.data.data.services,
        sun: result.data.data.sun,
        mon: result.data.data.mon,
        tue: result.data.data.tue,
        wed: result.data.data.wed,
        thu: result.data.data.thu,
        fri: result.data.data.fri,
        sat: result.data.data.sat,
        S_id: result.data.data.cityDetail.id,
      });
      setid({ id: result.data.data.id });
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  };

  const selectpickerData = (e) => {
    setid({ id: e });
    Getview(e);
    navigate(`/driving-school-details/view/${e}`);
  };

  useEffect(() => {
    Dropdown_Name();
    Getview("");
  }, []);

  let count = 10;
  let swalCountdownInterval;
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: "../../icon/alert.svg",
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr("disabled", true);
          swalCountdownInterval = setInterval(function () {
            count--;
            if (count < 1) {
              $(".counter").text(`OK`);
              $(".swal2-confirm").attr("disabled", false);
              clearInterval(swalCountdownInterval);
            } else {
              $(".counter").text(`OK (${count})`);
            }
          }, 1000);
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data.id);
          reloadId.splice(ind, 1);
          const resultDelete = await API.post(
            `/delete_driving_school_detail/${id}`,
            {},
            { headers: { authorization: `${token}` } }
          );
          if (resultDelete.data.status) {
            navigate("/driving-school-details");
            toast.success(resultDelete.data.response_message);
          } else {
            toast.success(resultDelete.data.response_message);
          }
          // if (reloadId.length === 0) {
          //   window.location.replace(
          //     `${process.env.REACT_APP_BASE_URL}driving_Detail`
          //   );

          // } else {
          //   window.location.replace(
          //     `${process.env.REACT_APP_BASE_URL}view/driving_Detail/${reloadId[0]}`
          //   );
          // }
        } else {
          count = 10;
          clearInterval(swalCountdownInterval);
        }
      });
  };
  
  const VirtualizedSelect = ({ options, ...rest }) => {
     
    return (
      <Select
        {...rest}
        className="customMulSelect wv-250"
        classNamePrefix="react-select"
        components={{ MenuList: VirtualizedMenuList }}
        options={options}
        onChange={(e) => selectpickerData(e.value)}
        value={options?.find((item)=>item.value==id.id)}
      />
    );
  };
  
  const VirtualizedMenuList = ({ children, maxHeight, getValue }) => {
    const selectedOption = getValue()[0];
    const selectedIndex = options.indexOf(selectedOption);
  
    return (
      <FixedSizeList height={maxHeight} itemCount={options.length} itemSize={35} width="100%">
        {({ index, style }) => (
          <div style={{ ...style}}>
            {children[index]}
          </div>
        )}
      </FixedSizeList>
    );
  };
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>
          <Link
            to="/driving-school-details"
            className="btn btn-primary btn-icon-lg me-3"
          >
            <i className="bx bxs-left-arrow-alt"></i>
          </Link>
          Driving School Details View
        </h3>
        <div className="page-heading-right">
        <VirtualizedSelect options={options} className="wv-200" />
          {userPermission["Driving-School-Detail"]?.edit==1&&<Link to={`/driving-school-details/edit/${id.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">
              Edit
            </Button>
          </Link>}
          {userPermission["Driving-School-Detail"]?.delete==1&&<Button
            variant="danger ms-3 my-1 btn-icon-lg"
            type="button"
            onClick={(i) => Deleteproject()}
          >
            <i className="bx bx-trash-alt"></i>
          </Button>}
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body>
            {/* {isLoading && <div className="loader"></div>} */}
            <Row>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">City Name</p>
                  <span>{Data.city_id}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Zip Code</p>
                  <span>{Data.zip_code}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Driving School Name</p>
                  <span>{Data.name}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Address</p>
                  <span>{Data.address}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Contect Info</p>
                  <span>{`${Data.contactNumber1} ,${Data.contactNumber2}`}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Website</p>
                  <a href={Data.website} target="_blank">
                    <Button
                      variant="outline-info"
                      size="sm"
                      className="btn-icon"
                    >
                      <i className="bx bx-link-external"></i>
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default DrivingSchoolDetailsView;
