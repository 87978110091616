import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';
import { AuthContext } from '../AppContext.jsx';

const Sidebar = ({ sidebar }) => {
    const { classActive, setClassActive, userPermission } = useContext(AuthContext)
    const handleActiveClass = (name) => {
        let data = {
            drp1: false,
            drp2: false,
            drp3: false,
            drp4: false,
            drp5: false,
            drp6: false,
            drp7: false,
        }
        data[name] = classActive[name] === true ? false : true
        setClassActive(data)
    };

    return (
        <>
            <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
                <div className="sidebar-header">
                    <div className="d-flex justify-content-between">
                        <div className='sidebar-logo'>
                            <Link to="/Home">
                                <Logo />
                                <LogoMini />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-item">
                            <NavLink to="/Home" className='sidebar-link'>
                                <i className='bx bxs-home'></i>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        {(userPermission["DL-Rc-info"]?.view == 1 || userPermission["Traffic-State"]?.view == 1 || userPermission["Traffic-Rules"]?.view == 1) && <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp1") }} className="sidebar-link">
                                <i className='bx bxs-credit-card-front'></i>
                                <span>RC Information</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp1 ? "active" : "inactive"}`} >
                                {userPermission["DL-Rc-info"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/dl-rc-info">
                                        <span>DL/RC Info</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Traffic-State"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/traffic-state">
                                        <span>Traffic State</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Traffic-Rules"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/traffic-rule">
                                        <span>Traffic Rule</span>
                                    </NavLink>
                                </li>}
                            </ul>
                        </li>}
                        {(userPermission["Affilation-Dashboard"]?.view == 1 || userPermission["Affilation-Program"]?.view == 1 || userPermission["Service-City"]?.view == 1 || userPermission["Service-Provider"]?.view == 1) && <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp6") }} className="sidebar-link">
                                <i className="bx bxs-user"></i>
                                <span>Affiliation</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp6 ? "active" : "inactive"}`} >
                                {userPermission["Affilation-Dashboard"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affiliation-dashboard">
                                        <span>Affiliation Dashboard</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Affilation-Program"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affiliation">
                                        <span>Affiliation Program</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-City"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affiliation-city">
                                        <span>All Services City List</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-Provider"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/service-provider-city">
                                        <span>Services Provider City</span>
                                    </NavLink>
                                </li>}
                            </ul>
                        </li>}
                        {(userPermission["News-Category"]?.view == 1 || userPermission["News-Headlines"]?.view == 1 || userPermission["News"]?.view == 1 || userPermission["News"]?.view == 1) && <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp2") }} className="sidebar-link">
                                <i className='bx bxs-news' ></i>
                                <span>News</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp2 ? "active" : "inactive"}`} >
                                {userPermission["News-Category"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/news-category">
                                        <span>News Category</span>
                                    </NavLink>
                                </li>}
                                {userPermission["News-Headlines"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/news-headline">
                                        <span>News Headline</span>
                                    </NavLink>
                                </li>}
                                {userPermission["News"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/news">
                                        <span>News</span>
                                    </NavLink>
                                </li>}
                                <li className="submenu-item">
                                    <NavLink to="/tags">
                                        <span>Tags</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>}

                        {(userPermission["Driving-School-State"]?.view == 1 || userPermission["Driving-School-City"]?.view == 1 || userPermission["Driving-School-Area"]?.view == 1 || userPermission["Driving-School-Detail"]?.view == 1) &&
                            <li className="sidebar-item has-sub">
                                <div onClick={() => { handleActiveClass("drp3") }} className="sidebar-link">
                                    <i className='bx bxs-bus-school' ></i>
                                    <span>Driving School</span>
                                </div>
                                <ul className={`${"submenu"} ${classActive.drp3 ? "active" : "inactive"}`} >
                                    {userPermission["Driving-School-State"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/driving-school-state">
                                            <span>DS State</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Driving-School-City"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/driving-school-city">
                                            <span>DS City</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Driving-School-Area"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/driving-school-area">
                                            <span>DS Area</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Driving-School-Detail"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/driving-school-details">
                                            <span>DS Details</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                            </li>}

                        {(userPermission["Service-Center-State"]?.view == 1 || userPermission["Service-Center-City"]?.view == 1 || userPermission["Service-Center-Brand"]?.view == 1 || userPermission["Service-Center-Data"]?.view == 1 || userPermission["Service-Center-Dealer"]?.view == 1) && <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp4") }} className="sidebar-link">
                                <i className='bx bxs-wrench'></i>
                                <span>Service Center</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp4 ? "active" : "inactive"}`} >
                                {userPermission["Service-Center-State"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/service-center-state">
                                        <span>State</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-Center-City"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/service-center-city">
                                        <span>City</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-Center-Brand"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/service-center-brand">
                                        <span>Brand</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-Center-Data"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/service-center">
                                        <span>Service Center</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-Center-Dealer"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/service-dealer">
                                        <span>Service Dealer</span>
                                    </NavLink>
                                </li>}
                            </ul>
                        </li>}
                        {(userPermission["Vehicle-info2-Category"]?.view == 1
                            || userPermission["Vehicle-info2-Brand"]?.view == 1
                            || userPermission["Vehicle-info2-Information"]?.view == 1
                            || userPermission["Vehicle-info2-Body-Types"]?.view == 1
                            || userPermission["Vehicle-info2-Key-Specification"]?.view == 1
                            || userPermission["Vehicle-info2-Variant-Specification"]?.view == 1
                            || userPermission["Vehicle-info2-Compare"]?.view == 1) && <li className="sidebar-item has-sub">
                                <div onClick={() => { handleActiveClass("drp5") }} className="sidebar-link">
                                    <i className='bx bxs-car' ></i>
                                    <span>Vehicle Info 2.0</span>
                                </div>
                                <ul className={`${"submenu"} ${classActive.drp5 ? "active" : "inactive"}`} >
                                    {userPermission["Vehicle-info2-Category"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/vehicle-category">
                                            <span>Category</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Vehicle-info2-Brand"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/vehicle-brand">
                                            <span>Brand</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Vehicle-info2-Information"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/vehicle-information">
                                            <span>Vehicle Information</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Vehicle-info2-Body-Types"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/vehicle-body-type">
                                            <span>Body Type</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Vehicle-info2-Key-Specification"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/vehicle-key-specification">
                                            <span>Key Specification</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Vehicle-info2-Variant-Specification"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/vehicle-variant-specification">
                                            <span>Veriant Specification</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Vehicle-info2-Compare"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/vehicle-compare">
                                            <span>Compares</span>
                                        </NavLink>
                                    </li>}
                                    {userPermission["Vehicle-info2-Compare"]?.view == 1 && <li className="submenu-item">
                                        <NavLink to="/recommended-vehicle">
                                            <span>Recommended</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                            </li>}

                        <li className="sidebar-item">
                            <NavLink to="/fuel-price" className='sidebar-link '>
                                <i className='bx bxs-gas-pump'></i>
                                <span>Fuel Price</span>
                            </NavLink>
                        </li>

                        {userPermission["Shorts"]?.view == 1 && <li className="sidebar-item">
                            <NavLink to="/shorts" className='sidebar-link '>
                                <i className='bx bxs-video-recording' ></i>
                                <span>Shorts Video</span>
                            </NavLink>
                        </li>}

                        <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp7") }} className="sidebar-link">
                                <i className='bx bxs-lock-open-alt' ></i>
                                <span>User Permission</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp7 ? "active" : "inactive"}`} >
                                <li className="submenu-item">
                                    <NavLink to="/module">
                                        <span>Module</span>
                                    </NavLink>
                                </li>
                                <li className="submenu-item">
                                    <NavLink to="/role">
                                        <span>Role</span>
                                    </NavLink>
                                </li>
                                <li className="submenu-item">
                                    <NavLink to="/user">
                                        <span>Permission</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar