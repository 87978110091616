import axios from 'axios';
import 'boxicons/css/boxicons.css';
import 'rsuite/dist/rsuite.css';
import './bootstrap.css';
import { useContext, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import PrivetRoutes from './Component/PrivetRoutes';
import Protected from './Component/Protected';
import Home from './pages/Home';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import './utilities.css';

import RCDLinfo from './pages/DLRCinfo/RCDLInfo.jsx';
import RCDLinfoAdd from './pages/DLRCinfo/RCDLinfoAdd.jsx';
import RCDLinfoEdit from './pages/DLRCinfo/RCDLinfoEdit.jsx';
import RCDLinfoView from './pages/DLRCinfo/RCDLinfoView.jsx';

import TrafficState from './pages/TrafficState/TrafficState.jsx';
import TrafficStateAdd from './pages/TrafficState/TrafficStateAdd.jsx';
import TrafficStateEdit from './pages/TrafficState/TrafficStateEdit.jsx';
import TrafficStateView from './pages/TrafficState/TrafficStateView.jsx';

import TrafficRule from './pages/TrafficRule/TrafficRule.jsx';
import TrafficRuleAdd from './pages/TrafficRule/TrafficRuleAdd.jsx';
import TrafficRuleLanguage from './pages/TrafficRule/TrafficRuleLanguage.jsx';
import TrafficRuleView from './pages/TrafficRule/TrafficRuleView.jsx';

import AffiliationDashboard from './pages/AffiliationDashboard/AffiliationDashboard';

import Affiliation from './pages/Affiliation/Affiliation';
import AffiliationAdd from './pages/Affiliation/AffiliationAdd';
import AffiliationData from './pages/Affiliation/AffiliationData';
import AffiliationLanguage from './pages/Affiliation/AffiliationLanguage';
import AffiliationOffer from './pages/Affiliation/AffiliationOffer';
import AffiliationView from './pages/Affiliation/AffiliationView';

import AffiliationCity from './pages/AffiliationCity/AffiliationCity';
import AffiliationCityAdd from './pages/AffiliationCity/AffiliationCityAdd';
import AffiliationCityView from './pages/AffiliationCity/AffiliationCityView';

import ServiceProviderCity from './pages/ServiceProviderCity/ServiceProviderCity';
import ServiceProviderCityAdd from './pages/ServiceProviderCity/ServiceProviderCityAdd';
import ServiceProviderCityView from './pages/ServiceProviderCity/ServiceProviderCityView';

import NewsCategory from './pages/NewsCategory/NewsCategory.jsx';
import NewsCategoryAdd from './pages/NewsCategory/NewsCategoryAdd.jsx';
import NewsCategoryEdit from './pages/NewsCategory/NewsCategoryEdit.jsx';
import NewsCategoryView from './pages/NewsCategory/NewsCategoryView.jsx';

import NewsHeadline from './pages/NewsHeadline/NewsHeadline.jsx';
import NewsHeadlineAdd from './pages/NewsHeadline/NewsHeadlineAdd.jsx';
import NewsHeadlineView from './pages/NewsHeadline/NewsHeadlineView.jsx';

import News from './pages/News/News';
import NewsAdd from './pages/News/NewsAdd.jsx';
import NewsEdit from './pages/News/NewsEdit.jsx';
import NewsView from './pages/News/NewsView.jsx';

import Tags from './pages/Tags/Tags.jsx';
import TagsAdd from './pages/Tags/TagsAdd.jsx';
import TagsView from './pages/Tags/TagsView.jsx';

import DrivingSchoolState from './pages/DrivingSchoolState/DrivingSchoolState.jsx';
import DrivingSchoolStateAdd from './pages/DrivingSchoolState/DrivingSchoolStateAdd.jsx';
import DrivingSchoolStateView from './pages/DrivingSchoolState/DrivingSchoolStateView.jsx';

import DrivingSchoolCity from './pages/DrivingSchoolCity/DrivingSchoolCity.jsx';
import DrivingSchoolCityAdd from './pages/DrivingSchoolCity/DrivingSchoolCityAdd.jsx';
import DrivingSchoolCityView from './pages/DrivingSchoolCity/DrivingSchoolCityView.jsx';

import DrivingSchoolArea from './pages/DrivingSchoolArea/DrivingSchoolArea.jsx';
import DrivingSchoolAreaAdd from './pages/DrivingSchoolArea/DrivingSchoolAreaAdd.jsx';
import DrivingSchoolAreaView from './pages/DrivingSchoolArea/DrivingSchoolAreaView.jsx';

import DrivingSchoolDetails from './pages/DrivingSchoolDetails/DrivingSchoolDetails.jsx';
import DrivingSchoolDetailsAdd from './pages/DrivingSchoolDetails/DrivingSchoolDetailsAdd.jsx';
import DrivingSchoolDetailsView from './pages/DrivingSchoolDetails/DrivingSchoolDetailsView.jsx';

import ServiceCenterState from './pages/ServiceCenterState/ServiceCenterState.jsx';
import ServiceCenterStateAdd from './pages/ServiceCenterState/ServiceCenterStateAdd.jsx';
import ServiceCenterStateView from './pages/ServiceCenterState/ServiceCenterStateView.jsx';

import ServiceCenterCity from './pages/ServiceCenterCity/ServiceCenterCity.jsx';
import ServiceCenterCityAdd from './pages/ServiceCenterCity/ServiceCenterCityAdd.jsx';
import ServiceCenterCityView from './pages/ServiceCenterCity/ServiceCenterCityView.jsx';

import ServiceCenterBrand from './pages/ServiceCenterBrand/ServiceCenterBrand.jsx';
import ServiceCenterBrandAdd from './pages/ServiceCenterBrand/ServiceCenterBrandAdd.jsx';
import ServiceCenterBrandView from './pages/ServiceCenterBrand/ServiceCenterBrandView.jsx';

import ServiceCenter from './pages/ServiceCenter/ServiceCenter.jsx';
import ServiceCenterAdd from './pages/ServiceCenter/ServiceCenterAdd.jsx';
import ServiceCenterView from './pages/ServiceCenter/ServiceCenterView.jsx';

import ServiceDealer from './pages/ServiceDealer/ServiceDealer.jsx';
import ServiceDealerAdd from './pages/ServiceDealer/ServiceDealerAdd.jsx';
import ServiceDealerView from './pages/ServiceDealer/ServiceDealerView.jsx';

import VehicleCategory from './pages/VehicleCategory/VehicleCategory.jsx';
import VehicleCategoryAdd from './pages/VehicleCategory/VehicleCategoryAdd.jsx';
import VehicleCategoryEdit from './pages/VehicleCategory/VehicleCategoryEdit.jsx';
import VehicleCategoryView from './pages/VehicleCategory/VehicleCategoryView.jsx';

import VehicleBrand from './pages/VehicleBrand/VehicleBrand.jsx';
import VehicleBrandAdd from './pages/VehicleBrand/VehicleBrandAdd.jsx';
import VehicleBrandEdit from './pages/VehicleBrand/VehicleBrandEdit.jsx';
import VehicleBrandView from './pages/VehicleBrand/VehicleBrandView.jsx';

import VehicleInformation from './pages/VehicleInformation/VehicleInformation.jsx';
import VehicleInformationAdd from './pages/VehicleInformation/VehicleInformationAdd.jsx';
import VehicleInformationEdit from './pages/VehicleInformation/VehicleInformationEdit.jsx';
import VehicleInformationView from './pages/VehicleInformation/VehicleInformationView.jsx';

import VehicleBodyType from './pages/VehicleBodyType/VehicleBodyType.jsx';
import VehicleBodyTypeAdd from './pages/VehicleBodyType/VehicleBodyTypeAdd.jsx';
import VehicleBodyTypeEdit from './pages/VehicleBodyType/VehicleBodyTypeEdit.jsx';
import VehicleBodyTypeView from './pages/VehicleBodyType/VehicleBodyTypeView.jsx';

import VehicleKeySpecification from './pages/VehicleKeySpecification/VehicleKeySpecification.jsx';
import VehicleKeySpecificationAdd from './pages/VehicleKeySpecification/VehicleKeySpecificationAdd.jsx';
import VehicleKeySpecificationEdit from './pages/VehicleKeySpecification/VehicleKeySpecificationEdit.jsx';
import VehicleKeySpecificationView from './pages/VehicleKeySpecification/VehicleKeySpecificationView.jsx';

import VehicleVariantSpecification from './pages/VehicleVariantSpecification/VehicleVariantSpecification.jsx';
import VehicleVariantSpecificationAdd from './pages/VehicleVariantSpecification/VehicleVariantSpecificationAdd.jsx';
import VehicleVariantSpecificationEdit from './pages/VehicleVariantSpecification/VehicleVariantSpecificationEdit.jsx';
import VehicleVariantSpecificationView from './pages/VehicleVariantSpecification/VehicleVariantSpecificationView.jsx';

import VehicleCompare from './pages/VehicleCompare/VehicleCompare.jsx';
import VehicleCompareAdd from './pages/VehicleCompare/VehicleCompareAdd.jsx';
import VehicleCompareView from './pages/VehicleCompare/VehicleCompareView.jsx';

import Recommended from './pages/Recommended/Recommended.jsx';

import FuelPrice from './pages/FuelPrice/FuelPrice.jsx';
import FuelPriceAdd from './pages/FuelPrice/FuelPriceAdd.jsx';
import FuelPriceEdit from './pages/FuelPrice/FuelPriceEdit.jsx';
import FuelPriceView from './pages/FuelPrice/FuelPriceView.jsx';
import { AuthContext } from './AppContext.jsx';

import Module from "./pages/Module/Module"
import AddModule from "./pages/Module/ModuleAdd"
import ViewModule from "./pages/Module/ModuleView"
import EditModule from "./pages/Module/ModuleEdit"

import Role from './pages/Role/Role';
import RoleAdd from './pages/Role/RoleAdd';
import ViewRole from './pages/Role/RoleView';

import User from './pages/Permission/User';
import AddUser from './pages/Permission/UserAdd';
import ViewUser from './pages/Permission/UserView';
import EditUser from './pages/Permission/UserEdit';
import AddImageForm from './pages/Affiliation/AddImageForm.jsx';

import Shorts from './pages/Shorts/Shorts.jsx';
import ShortsAdd from './pages/Shorts/ShortsAdd.jsx';
import ShortsView from './pages/Shorts/ShortsView.jsx';

export const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })


// export const AuthContext = createContext()

function App() {
    const { userPermission } = useContext(AuthContext)

    const [classActive, setClassActive] = useState({
        drp1: false,
        drp2: false,
        drp3: false,
        drp4: false,
        drp5: false,
        drp6: false,
    });

    return (
        <>

            <BrowserRouter>
                <Routes>
                    <Route element={<Protected />}>
                        <Route path="/" element={<Login />} />
                    </Route>
                    <Route element={<PrivetRoutes />}>
                        <Route path="/Home" element={<Home />} />
                        <Route path="*" element={<PageNotFound />} />

                        {userPermission["DL-Rc-info"]?.view == 1 &&
                            <Route path="/dl-rc-info" element={<RCDLinfo />} />
                        }
                        {userPermission["DL-Rc-info"]?.add == 1 &&
                            <Route path="/dl-rc-info/add" element={<RCDLinfoAdd />} />
                        }
                        {userPermission["DL-Rc-info"]?.edit == 1 &&
                            <Route path="/dl-rc-info/edit/:id" element={<RCDLinfoEdit />} />
                        }
                        {userPermission["DL-Rc-info"]?.view == 1 &&
                            <Route path="/dl-rc-info/view/:id" element={<RCDLinfoView />} />
                        }

                        {userPermission["Traffic-State"]?.view == 1 &&
                            <Route path="/traffic-state" element={<TrafficState />} />
                        }
                        {userPermission["Traffic-State"]?.add == 1 &&
                            <Route path="/traffic-state/add" element={<TrafficStateAdd />} />
                        }
                        {userPermission["Traffic-State"]?.edit == 1 &&
                            <Route path="/traffic-state/edit/:id" element={<TrafficStateEdit />} />
                        }
                        {userPermission["Traffic-State"]?.view == 1 &&
                            <Route path="/traffic-state/view/:id" element={<TrafficStateView />} />
                        }

                        {userPermission["Traffic-Rules"]?.view == 1 &&
                            <Route path="/traffic-rule" element={<TrafficRule />} />
                        }
                        {userPermission["Traffic-Rules"]?.add == 1 &&
                            <Route path="/traffic-rule/add" element={<TrafficRuleAdd />} />
                        }
                        {userPermission["Traffic-Rules"]?.edit == 1 &&
                            <Route path="/traffic-rule/edit/:id" element={<TrafficRuleAdd />} />
                        }
                        {userPermission["Traffic-Rules"]?.view == 1 &&
                            <Route path="/traffic-rule/view/:id" element={<TrafficRuleView />} />
                        }
                        {userPermission["Traffic-Language"]?.view == 1 &&
                            <Route path="/traffic-rule/language" element={<TrafficRuleLanguage />} />
                        }

                        {userPermission["Affilation-Dashboard"]?.view == 1 &&
                            <Route path="/affiliation-dashboard" element={<AffiliationDashboard />} />
                        }

                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation" element={<Affiliation />} />
                        }
                        {userPermission["Affilation-Program"]?.add == 1 &&
                            <Route path="/affiliation/add" element={<AffiliationAdd />} />
                        }
                        {userPermission["Affilation-Program"]?.edit == 1 &&
                            <Route path="/affiliation/edit" element={<AffiliationAdd />} />
                        }
                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation/view" element={<AffiliationView />} />
                        }
                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation/view/:id" element={<AffiliationView />} />
                        }
                        {userPermission["Affilation-Data"]?.view == 1 &&
                            <Route path="/affiliation/data" element={<AffiliationData />} />
                        }
                        {userPermission["Affilation-Program"]?.view == 1 &&
                            <Route path="/affiliation/offer" element={<AffiliationOffer />} />
                        }
                        {userPermission["Language"]?.view == 1 &&
                            <Route path="/affiliation/language" element={<AffiliationLanguage />} />
                        }
                        {/* {  userPermission["Language"]?.view == 1 && */}
                        <Route path="/affiliation/addimages" element={<AddImageForm />} />
                        {/* } */}

                        {userPermission["Service-City"]?.view == 1 &&
                            <Route path="affiliation-city" element={<AffiliationCity />} />
                        }
                        {userPermission["Service-City"]?.add == 1 &&
                            <Route path="affiliation-city/add" element={<AffiliationCityAdd />} />
                        }
                        {userPermission["Service-City"]?.edit == 1 &&
                            <Route path="affiliation-city/edit/:id" element={<AffiliationCityAdd />} />
                        }
                        {userPermission["Service-City"]?.view == 1 &&
                            <Route path="affiliation-city/view" element={<AffiliationCityView />} />
                        }
                        {userPermission["Service-City"]?.view == 1 &&
                            <Route path="affiliation-city/view/:id" element={<AffiliationCityView />} />
                        }

                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="service-provider-city" element={<ServiceProviderCity />} />
                        }
                        {userPermission["Service-Provider"]?.add == 1 &&
                            <Route path="service-provider-city/add" element={<ServiceProviderCityAdd />} />
                        }
                        {userPermission["Service-Provider"]?.edit == 1 &&
                            <Route path="service-provider-city/edit/:id" element={<ServiceProviderCityAdd />} />
                        }
                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="service-provider-city/view" element={<ServiceProviderCityView />} />
                        }
                        {userPermission["Service-Provider"]?.view == 1 &&
                            <Route path="service-provider-city/view/:id" element={<ServiceProviderCityView />} />
                        }

                        {userPermission["News-Category"]?.view == 1 &&
                            <Route path="/news-category" element={<NewsCategory />} />
                        }
                        {userPermission["News-Category"]?.add == 1 &&
                            <Route path="/news-category/add" element={<NewsCategoryAdd />} />
                        }
                        {userPermission["News-Category"]?.edit == 1 &&
                            <Route path="/news-category/edit/:id" element={<NewsCategoryEdit />} />
                        }
                        {userPermission["News-Category"]?.view == 1 &&
                            <Route path="/news-category/view/:id" element={<NewsCategoryView />} />
                        }

                        {userPermission["News-Headlines"]?.view == 1 &&
                            <Route path="/news-headline" element={<NewsHeadline />} />
                        }
                        {userPermission["News-Headlines"]?.add == 1 &&
                            <Route path="/news-headline/add" element={<NewsHeadlineAdd />} />
                        }
                        {userPermission["News-Headlines"]?.edit == 1 &&
                            <Route path="/news-headline/edit/:id" element={<NewsHeadlineAdd />} />
                        }
                        {userPermission["News-Headlines"]?.view == 1 &&
                            <Route path="/news-headline/view/:id" element={<NewsHeadlineView />} />
                        }

                        {userPermission["News"]?.view == 1 &&
                            <Route path="/news" element={<News />} />
                        }
                        {userPermission["News"]?.add == 1 &&
                            <Route path="/news/add" element={<NewsAdd />} />
                        }
                        {userPermission["News"]?.edit == 1 &&
                            <Route path="/news/edit/:id" element={<NewsEdit />} />
                        }
                        {userPermission["News"]?.view == 1 &&
                            <Route path="/news/view/:id" element={<NewsView />} />
                        }

                        {userPermission["RC-Details"]?.view == 1 &&
                            <Route path="/tags" element={<Tags />} />
                        }
                        {userPermission["RC-Details"]?.view == 1 &&
                            <Route path="/tags/add" element={<TagsAdd />} />
                        }
                        {userPermission["RC-Details"]?.view == 1 &&
                            <Route path="/tags/edit/:id" element={<TagsAdd />} />
                        }
                        {userPermission["RC-Details"]?.view == 1 &&
                            <Route path="/tags/view/:id" element={<TagsView />} />
                        }

                        {userPermission["Driving-School-State"]?.view == 1 &&
                            <Route path="/driving-school-state" element={<DrivingSchoolState />} />
                        }
                        {userPermission["Driving-School-State"]?.add == 1 &&
                            <Route path="/driving-school-state/add" element={<DrivingSchoolStateAdd />} />
                        }
                        {userPermission["Driving-School-State"]?.edit == 1 &&
                            <Route path="/driving-school-state/edit/:id" element={<DrivingSchoolStateAdd />} />
                        }
                        {userPermission["Driving-School-State"]?.view == 1 &&
                            <Route path="/driving-school-state/view/:id" element={<DrivingSchoolStateView />} />
                        }

                        {userPermission["Driving-School-City"]?.view == 1 &&
                            <Route path="/driving-school-city" element={<DrivingSchoolCity />} />
                        }
                        {userPermission["Driving-School-City"]?.add == 1 &&
                            <Route path="/driving-school-city/add" element={<DrivingSchoolCityAdd />} />
                        }
                        {userPermission["Driving-School-City"]?.edit == 1 &&
                            <Route path="/driving-school-city/edit/:id" element={<DrivingSchoolCityAdd />} />
                        }
                        {userPermission["Driving-School-City"]?.view == 1 &&
                            <Route path="/driving-school-city/view/:id" element={<DrivingSchoolCityView />} />
                        }

                        {userPermission["Driving-School-Area"]?.view == 1 &&
                            <Route path="/driving-school-area" element={<DrivingSchoolArea />} />
                        }
                        {userPermission["Driving-School-Area"]?.add == 1 &&
                            <Route path="/driving-school-area/add" element={<DrivingSchoolAreaAdd />} />
                        }
                        {userPermission["Driving-School-Area"]?.edit == 1 &&
                            <Route path="/driving-school-area/edit/:id" element={<DrivingSchoolAreaAdd />} />
                        }
                        {userPermission["Driving-School-Area"]?.view == 1 &&
                            <Route path="/driving-school-area/view/:id" element={<DrivingSchoolAreaView />} />
                        }

                        {userPermission["Driving-School-Detail"]?.view == 1 &&
                            <Route path="/driving-school-details" element={<DrivingSchoolDetails />} />
                        }
                        {userPermission["Driving-School-Detail"]?.add == 1 &&
                            <Route path="/driving-school-details/add" element={<DrivingSchoolDetailsAdd />} />
                        }
                        {userPermission["Driving-School-Detail"]?.edit == 1 &&
                            <Route path="/driving-school-details/edit/:id" element={<DrivingSchoolDetailsAdd />} />
                        }
                        {userPermission["Driving-School-Detail"]?.view == 1 &&
                            <Route path="/driving-school-details/view/:id" element={<DrivingSchoolDetailsView />} />
                        }

                        {userPermission["Service-Center-State"]?.view == 1 &&
                            <Route path="/service-center-state" element={<ServiceCenterState />} />
                        }
                        {userPermission["Service-Center-State"]?.add == 1 &&
                            <Route path="/service-center-state/add" element={<ServiceCenterStateAdd />} />
                        }
                        {userPermission["Service-Center-State"]?.edit == 1 &&
                            <Route path="/service-center-state/edit/:id" element={<ServiceCenterStateAdd />} />
                        }
                        {userPermission["Service-Center-State"]?.view == 1 &&
                            <Route path="/service-center-state/view/:id" element={<ServiceCenterStateView />} />
                        }

                        {userPermission["Service-Center-City"]?.view == 1 &&
                            <Route path="/service-center-city" element={<ServiceCenterCity />} />
                        }
                        {userPermission["Service-Center-City"]?.add == 1 &&
                            <Route path="/service-center-city/add" element={<ServiceCenterCityAdd />} />
                        }
                        {userPermission["Service-Center-City"]?.edit == 1 &&
                            <Route path="/service-center-city/edit/:id" element={<ServiceCenterCityAdd />} />
                        }
                        {userPermission["Service-Center-City"]?.view == 1 &&
                            <Route path="/service-center-city/view/:id" element={<ServiceCenterCityView />} />
                        }

                        {userPermission["Service-Center-Brand"]?.view == 1 &&
                            <Route path="/service-center-brand" element={<ServiceCenterBrand />} />
                        }
                        {userPermission["Service-Center-Brand"]?.add == 1 &&
                            <Route path="/service-center-brand/add" element={<ServiceCenterBrandAdd />} />
                        }
                        {userPermission["Service-Center-Brand"]?.edit == 1 &&
                            <Route path="/service-center-brand/edit/:id" element={<ServiceCenterBrandAdd />} />
                        }
                        {userPermission["Service-Center-Brand"]?.view == 1 &&
                            <Route path="/service-center-brand/view/:id" element={<ServiceCenterBrandView />} />
                        }

                        {userPermission["Service-Center-Data"]?.view == 1 &&
                            <Route path="/service-center" element={<ServiceCenter />} />
                        }
                        {userPermission["Service-Center-Data"]?.add == 1 &&
                            <Route path="/service-center/add" element={<ServiceCenterAdd />} />
                        }
                        {userPermission["Service-Center-Data"]?.edit == 1 &&
                            <Route path="/service-center/edit/:id" element={<ServiceCenterAdd />} />
                        }
                        {userPermission["Service-Center-Data"]?.view == 1 &&
                            <Route path="/service-center/view/:id" element={<ServiceCenterView />} />
                        }

                        {userPermission["Service-Center-Dealer"]?.view == 1 &&
                            <Route path="/service-dealer" element={<ServiceDealer />} />
                        }
                        {userPermission["Service-Center-Dealer"]?.add == 1 &&
                            <Route path="/service-dealer/add" element={<ServiceDealerAdd />} />
                        }
                        {userPermission["Service-Center-Dealer"]?.edit == 1 &&
                            <Route path="/service-dealer/edit/:id" element={<ServiceDealerAdd />} />
                        }
                        {userPermission["Service-Center-Dealer"]?.view == 1 &&
                            <Route path="/service-dealer/view/:id" element={<ServiceDealerView />} />
                        }

                        {userPermission["Vehicle-info2-Category"]?.view == 1 &&
                            <Route path="/vehicle-category" element={<VehicleCategory />} />
                        }
                        {userPermission["Vehicle-info2-Category"]?.add == 1 &&
                            <Route path="/vehicle-category/add" element={<VehicleCategoryAdd />} />
                        }
                        {userPermission["Vehicle-info2-Category"]?.edit == 1 &&
                            <Route path="/vehicle-category/edit/:id" element={<VehicleCategoryEdit />} />
                        }
                        {userPermission["Vehicle-info2-Category"]?.view == 1 &&
                            <Route path="/vehicle-category/view/:id" element={<VehicleCategoryView />} />
                        }

                        {userPermission["Vehicle-info2-Brand"]?.view == 1 &&
                            <Route path="/vehicle-brand" element={<VehicleBrand />} />
                        }
                        {userPermission["Vehicle-info2-Brand"]?.add == 1 &&
                            <Route path="/vehicle-brand/add" element={<VehicleBrandAdd />} />
                        }
                        {userPermission["Vehicle-info2-Brand"]?.edit == 1 &&
                            <Route path="/vehicle-brand/edit/:id" element={<VehicleBrandEdit />} />
                        }
                        {userPermission["Vehicle-info2-Brand"]?.view == 1 &&
                            <Route path="/vehicle-brand/view/:id" element={<VehicleBrandView />} />
                        }

                        {userPermission["Vehicle-info2-Information"]?.view == 1 &&
                            <Route path="/vehicle-information" element={<VehicleInformation />} />
                        }
                        {userPermission["Vehicle-info2-Information"]?.add == 1 &&
                            <Route path="/vehicle-information/add" element={<VehicleInformationAdd />} />
                        }
                        {userPermission["Vehicle-info2-Information"]?.edit == 1 &&
                            <Route path="/vehicle-information/edit/:id" element={<VehicleInformationEdit />} />
                        }
                        {userPermission["Vehicle-info2-Information"]?.view == 1 &&
                            <Route path="/vehicle-information/view/:id" element={<VehicleInformationView />} />
                        }

                        {userPermission["Vehicle-info2-Body-Types"]?.view == 1 &&
                            <Route path="/vehicle-body-type" element={<VehicleBodyType />} />
                        }
                        {userPermission["Vehicle-info2-Body-Types"]?.add == 1 &&
                            <Route path="/vehicle-body-type/add" element={<VehicleBodyTypeAdd />} />
                        }
                        {userPermission["Vehicle-info2-Body-Types"]?.edit == 1 &&
                            <Route path="/vehicle-body-type/edit/:id" element={<VehicleBodyTypeEdit />} />
                        }
                        {userPermission["Vehicle-info2-Body-Types"]?.view == 1 &&
                            <Route path="/vehicle-body-type/view/:id" element={<VehicleBodyTypeView />} />
                        }

                        {userPermission["Vehicle-info2-Key-Specification"]?.view == 1 &&
                            <Route path="/vehicle-key-specification" element={<VehicleKeySpecification />} />
                        }
                        {userPermission["Vehicle-info2-Key-Specification"]?.add == 1 &&
                            <Route path="/vehicle-key-specification/add" element={<VehicleKeySpecificationAdd />} />
                        }
                        {userPermission["Vehicle-info2-Key-Specification"]?.edit == 1 &&
                            <Route path="/vehicle-key-specification/edit/:id" element={<VehicleKeySpecificationEdit />} />
                        }
                        {userPermission["Vehicle-info2-Key-Specification"]?.view == 1 &&
                            <Route path="/vehicle-key-specification/view/:id" element={<VehicleKeySpecificationView />} />
                        }

                        {userPermission["Vehicle-info2-Variant-Specification"]?.view == 1 &&
                            <Route path="/vehicle-variant-specification" element={<VehicleVariantSpecification />} />
                        }
                        {userPermission["Vehicle-info2-Variant-Specification"]?.add == 1 &&
                            <Route path="/vehicle-variant-specification/add" element={<VehicleVariantSpecificationAdd />} />
                        }
                        {userPermission["Vehicle-info2-Variant-Specification"]?.edit == 1 &&
                            <Route path="/vehicle-variant-specification/edit/:id" element={<VehicleVariantSpecificationEdit />} />
                        }
                        {userPermission["Vehicle-info2-Variant-Specification"]?.view == 1 &&
                            <Route path="/vehicle-variant-specification/view/:id" element={<VehicleVariantSpecificationView />} />
                        }

                        {userPermission["Vehicle-info2-Compare"]?.view == 1 &&
                            <Route path='vehicle-compare' element={<VehicleCompare />} />
                        }
                        {userPermission["Vehicle-info2-Compare"]?.add == 1 &&
                            <Route path='vehicle-compare/add' element={<VehicleCompareAdd />} />
                        }
                        {userPermission["Vehicle-info2-Compare"]?.edit == 1 &&
                            <Route path='vehicle-compare/edit/:id' element={<VehicleCompareAdd />} />
                        }
                        {userPermission["Vehicle-info2-Compare"]?.view == 1 &&
                            <Route path='vehicle-compare/view/:id' element={<VehicleCompareView />} />
                        }

                        {userPermission["Vehicle-info2-Compare"]?.view == 1 &&
                            <Route path='recommended-vehicle' element={<Recommended />} />
                        }

                        <Route path="/fuel-price" element={<FuelPrice />} />
                        <Route path="/fuel-price/add" element={<FuelPriceAdd />} />
                        <Route path="/fuel-price/view/:id" element={<FuelPriceView />} />
                        <Route path="/fuel-price/edit/:id" element={<FuelPriceEdit />} />

                        <>
                            {userPermission["Shorts"]?.view == 1 &&
                                <Route path="/shorts" element={<Shorts />} />
                            }
                            {userPermission["Shorts"]?.add == 1 &&
                                <Route path="/shorts/add" element={<ShortsAdd />} />
                            }
                            {userPermission["Shorts"]?.edit == 1 &&
                                <Route path="/shorts/edit/:id" element={<ShortsAdd />} />
                            }
                            {userPermission["Shorts"]?.view == 1 &&
                                <Route path="/shorts/view/:id" element={<ShortsView />} />
                            }

                            <Route path="/module" element={<Module />} />
                            <Route path="/module/add" element={<AddModule />} />
                            <Route path="/module/view/:id" element={<ViewModule />} />
                            <Route path="/module/edit/:id" element={<EditModule />} />

                            <Route path="/role" element={<Role />} />
                            <Route path="/role/add" element={<RoleAdd />} />
                            <Route path="/role/edit/:id" element={<RoleAdd />} />
                            <Route path="/role/view/:id" element={<ViewRole />} />

                            <Route path="/user" element={<User />} />
                            <Route path="/user/add" element={<AddUser />} />
                            <Route path="/user/view/:id" element={<ViewUser />} />
                            <Route path="/user/edit/:id" element={<EditUser />} /></>

                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer position='bottom-right' autoClose={500} />
        </>
    )
}

export default App;
