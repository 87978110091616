import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Card, Table, Form } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import { API } from "../../App";
import $, { data } from "jquery";
import Swal from "sweetalert2";
import { AuthContext } from "../../AppContext";
import { toast } from "react-toastify";

const VehicleCompare = () => {
  const { userPermission, token } = useContext(AuthContext)
  const [allCompareData, setAllCompareData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState("10");
  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    setCurrent(page);
  };
  useEffect(() => {
    getAllCompareData()
  }, [current,limit])

  const getAllCompareData = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData()
      formData.append('limit', limit)
      formData.append('page', current)
      const response = await API.post("/get_all_compareData", formData,{ headers: { authorization: `${token}` } })
      setAllCompareData(response?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  let count = 10
    let swalCountdownInterval
    const DeleteData = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await API.post(`/delete_compareData/${id}`, {},{ headers: { authorization: `${token}` } });
                    getAllCompareData()
                    setCurrent(1)
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };
    const handleLive=async(id)=>{
      setIsLoading(true)
      try {
          const Result = await API.post("/vehicle_compare_live", {id}, { headers: { authorization: `${token}` } })
          if(Result.data.status){
            toast.success(Result.data.response_message)
        }else{
        toast.error(Result.data.response_message)
        }
      } catch (error) {
          setIsLoading(false)
      } finally {
          setIsLoading(false)
      }

  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Vehicle Compare</h3>
        <div className="page-heading-right">
         {userPermission["Vehicle-info2-Compare"]?.add==1&& <Link to="/vehicle-compare/add" className="my-1 ms-3">
            <Button variant="primary" value="create">Add New</Button>
          </Link>}
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th width="6%" className='text-center'>No</th>
                  <th width="16%">Category Name</th>
                  <th width="25%">Vehicle 1</th>
                  <th width="25%">Vehicle 2</th>
                  <th width="10%" className='text-center'>Status</th>
                  <th width="10%" className='text-center'>Compare Live</th>
                  <th width="10%" className='text-center' >Action</th>
                </tr>
              </thead>
              <tbody>
                {allCompareData?.data?.length > 0 && allCompareData?.data?.map((item,i) => {
                  return (<tr key={i}>
                    <td className='text-center'>{(current === 1) ? i + 1 : current * limit + i + 1 - limit}</td>
                    <td>{item?.categoryName?.category_name}</td>
                    <td>{item?.vehicle1_name?.model_name}</td>
                    <td>{item?.vehicle2_name?.model_name}</td>
                    <td className='text-center'><Form.Check type="switch"
                    disabled={userPermission["Vehicle-info2-Compare"]?.edit!=1} checked={item?.status} /></td>
                    <td className='text-center'><Button variant="info px-3" 
                    disabled={userPermission["Vehicle-info2-Compare"]?.edit!=1} size="sm" onClick={()=>handleLive(item.id)}>Live</Button></td>
                    <td className='text-center'>
                      <Link to={`/vehicle-compare/view/${item.id}`}>
                        <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                      </Link>
                      {userPermission["Vehicle-info2-Compare"]?.delete==1&&<Button variant="outline-danger" onClick={()=>DeleteData(item?.id)} size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                    }</td>
                  </tr>)
                })}
                {/* <tr>
                  <td colSpan="100%" className="p-0">
                    <div className='no-found'>
                      <img src="../../not-found/image.svg" />
                      <p>No Found Compares</p>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </Table>
            <div className="pagination-custom">
              <Pagination
                showTitle={false}
                className="pagination-data"
                onChange={onChange}
                total={allCompareData.totalcount}
                current={current}
                pageSize={10}
                showSizeChanger={false}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default VehicleCompare

