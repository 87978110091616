import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../App";
import Cookies from "js-cookie";
import Layout from "../../layout/Layout";

const RoleAdd = () => {
    const token = Cookies.get("fmljwt");
    const navigate = useNavigate();
    const [validated, setvalidated] = useState(false)
    const [loadData, setloadData] = useState(false)
    const [permission, setPermission] = useState([])
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [data, setData] = useState({
        create: [],
        edit: [],
        delete: [],
        list: []
    })
    const [isSelect, setIsSelect] = useState({
        create: false,
        edit: false,
        delete: false,
        list: false
    })

    useEffect(() => {
        setIsSelect({
            ...isSelect,
            create: data?.create?.length > 0 && data?.create?.every(element => selectedGroup.includes(element)),
            edit: data?.edit?.length > 0 && data?.edit?.every(element => selectedGroup.includes(element)),
            delete: data?.delete?.length > 0 && data?.delete?.every(element => selectedGroup.includes(element)),
            list: data?.list?.length > 0 && data?.list?.every(element => selectedGroup.includes(element))
        })
    }, [selectedGroup])

    const params = useParams()
    const [roleName, setRoleName] = useState("")
    const getData = async () => {
        const res = await API.post("/permission/get-permission-role", {}, { headers: { authorization: `${token}` } })
        setPermission(res.data.data)
        const updatedData = {};
        res.data.data?.forEach((item) => {
            const permissionType = item?.name?.split("-")[item?.name?.split("-")?.length - 1];

            if (!updatedData[permissionType]) {
                updatedData[permissionType] = []; // Initialize array if not exists
            }

            updatedData[permissionType].push(item.id);
        });

        setData({ ...data, ...updatedData });
        setloadData(true)
    }

    const permissionGroup = permission

    //  ******************** 1 ************************

    const Checkbox = ({ id, label, checked, onChange }) => (
        <Col md={3}>
            <Form.Check
                type="checkbox"
                checked={checked}
                className="mt-2 mb-3"
                id={id}
                label={label}
                onChange={onChange}
            // required
            />
        </Col>
    );
    const editRolePermissionData = async () => {
        if (params.id) {
            const result = await API.post(`/permission/view-role`, { role_id: params.id }, { headers: { authorization: `${token}` } })
            setRoleName(result?.data?.data[0]?.UserHasRole?.name)
            setSelectedGroup(result?.data?.data?.map(item => item?.UserHasPermission?.id))
        }
    }
    const PermissionCheckboxGroup = ({ permissionGroup, selectedGroup, onChangeAll, onChangeGroup }) => {
        const operationMap = {};

        // Group permissions based on common names
        permissionGroup.forEach((val) => {

            let commonName = val.name.replace(/(Cretae|Edit|Delete|list|delete|create|List|edit)\s*$/, '').trim();
            commonName = commonName.slice(0, -1)
            if (!operationMap[commonName]) {
                operationMap[commonName] = [];
            }
            operationMap[commonName].push(val);
        });
        return (
            <>
                <Col md={12}>
                    <Form.Check
                        type="checkbox"
                        className="my-3"
                        id="all"
                        checked={selectedGroup.length === permissionGroup.length}
                        onChange={(e) => onChangeAll(e.target.checked)}
                        label="All Permissions"
                        required={selectedGroup.length <= 0}
                    />
                </Col>
                <Col md={12} className="mb-3">
                    <Row>
                        <Col md={3}>
                            <Form.Check
                                type="checkbox"
                                className="my-2"
                                id="view"
                                onChange={(e) => selectAllView(e.target.checked, "list")}
                                checked={isSelect?.list || data?.list?.every(element => selectedGroup.includes(element))}
                                label="All View"
                                required={selectedGroup.length <= 0}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Check
                                type="checkbox"
                                className="my-2"
                                onChange={(e) => selectAllView(e.target.checked, "create")}
                                label="All Add"
                                id="create"

                                checked={isSelect?.create || data?.create?.every(element => selectedGroup.includes(element))}

                                required={selectedGroup.length <= 0}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Check
                                type="checkbox"
                                className="my-2"
                                onChange={(e) => selectAllView(e.target.checked, "edit")}
                                label="All Edit"
                                checked={isSelect?.edit || data?.edit?.every(element => selectedGroup.includes(element))}
                                id="edit"

                                required={selectedGroup.length <= 0}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Check
                                type="checkbox"
                                className="my-2"
                                onChange={(e) => selectAllView(e.target.checked, "delete")}
                                label="All Delete"
                                checked={isSelect?.delete || data?.delete?.every(element => selectedGroup.includes(element))}
                                id="delete"

                                required={selectedGroup.length <= 0}
                            />
                        </Col>
                    </Row>
                </Col>
                {Object.entries(operationMap).map(([commonName, permissions]) => (
                    <React.Fragment key={commonName}>
                        <Col md={12} className="mb-2">
                            <strong>{commonName}</strong>
                        </Col>
                        {permissions.map((permission) => (
                            
                            <Checkbox
                                key={permission.id}
                                id={permission.id}
                                label={permission.name}
                                checked={selectedGroup.includes(permission.id)}
                                onChange={(e) => onChangeGroup(e, permission.id)}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </>
        );
    };

    const handleGroupCheckbox = (e, id) => {
        setSelectedGroup((prevSelected) => (
            e.target.checked
                ? [...prevSelected, id]
                : prevSelected.filter((selectedId) => selectedId !== id)
        ))
    };

    const handleCheckAll = (isChecked) => {
        const allIds = permissionGroup.map((val) => val.id);
        setSelectedGroup(isChecked ? allIds : []);
    };
    const selectAllView = (isChecked, name) => {
        // setIsSelect({ ...isSelect, [name]: isChecked });

        let allView = [...selectedGroup];

        permissionGroup.forEach((val) => {
            const permissionType = val?.name?.split("-")[val?.name?.split("-")?.length - 1];
            if (permissionType === name) {
                const index = allView.indexOf(val.id);

                if (index !== -1 && !isChecked) {
                    allView.splice(index, 1);
                } else if (index === -1 && isChecked) {
                    allView.push(val.id);
                }
            }
        });

        setSelectedGroup([...allView]);
    };
   
    const submitData = async (e) => {
        e.preventDefault()
        if (!params.id) {
            const ceateRole = await API.post("/permission/create-role", { name: roleName }, { headers: { authorization: `${token}` } })
            if (ceateRole?.data?.status == true) {
                const createPermission = await API.post("/permission/assing-permission", { role_id: ceateRole.data.data.id, permission_id: JSON.stringify(selectedGroup) }, { headers: { authorization: `${token}` } })
                if (createPermission?.data?.status == true) {
                    navigate(`/role`)
                    toast.success(createPermission?.data?.response_message)
                } else {
                    toast.error(createPermission?.data?.response_message)
                }
            }
        } else {
            const updateRolePermission = await API.post(`/permission/update-permission/${params.id}`, { name: roleName, ids: JSON.stringify(selectedGroup) }, { headers: { authorization: `${token}` } })
            if (updateRolePermission?.data?.status == true) {
                toast.success(updateRolePermission?.data?.response_message)
                navigate(`/role/view/${params.id}`)
            } else {
                toast.error(updateRolePermission?.data?.response_message)
            }
        }
    }

    useEffect(() => {
        getData()
        if (params.id) {
            editRolePermissionData()
        }
    }, [])
    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>{`${params.id ? `Role Edit` : "Role Add"}`}</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item>
                            <Link to="/home">
                                <i className="bx bx-home-alt me-2 fs-5"></i> Home
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/role">Role List</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Role {`${params.id ? "Edit" : "Add"}`}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Form onSubmit={submitData}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Label htmlFor="name">Role</Form.Label>
                                    <Form.Control type="text" className="mt-2 mb-3" name="name" value={roleName} onChange={(e) => setRoleName(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Name Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <PermissionCheckboxGroup
                                    permissionGroup={permissionGroup}
                                    selectedGroup={selectedGroup}
                                    onChangeAll={handleCheckAll}
                                    onChangeGroup={handleGroupCheckbox}
                                />
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button type="submit" variant="primary" className="me-3" >
                                Save
                            </Button>
                            <Link to="/role">
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </Layout >
        </>
    )
}

export default RoleAdd