import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import { API } from '../../App';
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';

var DropDownArr = []
var reloadId = [];
const VehicleVariantSpecificationView = () => {
  const { userPermission, token } = useContext(AuthContext)
  const navigate = useNavigate();
  const params = useParams();
  const [variableData, setvariableData] = useState([]);
  const [id, setid] = useState({ id: params.id });
  const [Data, setData] = useState([]);
  const [DropDropValue, setDropDropValue] = useState([])
  const Dropdown_Name = async () => {

    const Result = await API.post("/get_pricevariant_dropdown", {}, { headers: { authorization: `${token}` } })
    DropDownArr = []
    reloadId = []
    // DropDownArr.push({label:"Select Title" , value:"" })
    Result.data.data.map((val, i) => {
      DropDownArr.push({ label: val?.name, value: val.id })
      reloadId.push(val.id)
    })
    setDropDropValue(DropDownArr)
  }
  const Getview = async (Eid) => {
    console.log("sdiagvdajsavjn")
    const result = await API.post(`/get_variant_details/${Eid || params.id}`, {}, { headers: { authorization: `${token}` } });
    // SetData({
    //   id: result.data.Data[0]._id,
    //   category_id: result.data.Data[0].category_id.name,
    //   brand_id: result.data.Data[0].brand_id.name,
    //   vehicle_id: result.data.Data[0].Vehicle_name_id.name,
    //   Variant_id: result.data.Data[0].Vehicle_Variant_id.name,
    //   specification: result.data.Data[0].specification.name,
    //   specification_name: result.data.Data[0].specification_name,
    //   specification_value: result.data.Data[0].specification_value,
    //   key_specification: result.data.Data[0].key_specification.name,
    //   is_feature: result.data.Data[0].is_feature,
    //   is_specification: result.data.Data[0].is_specification,
    //   is_overview: result.data.Data[0].is_overview,
    // })
    // console.log("dksgjk")
    // setid({ id: Number(params.id) })
    setData(result.data.data)
  };

  const selectpickerData = (e) => {
    if (e) {
      setid({ id: e });
    }
    console.log("23465usdghajgj")
    Getview(e);
    navigate(`/vehicle-variant-specification/view/${e}`)
  };

  let count = 10
  let swalCountdownInterval
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data.id)
          reloadId.splice(ind, 1)
          const resultDeleted = await API.post(`/delete_specification_detsils/${params.id}`, {}, { headers: { authorization: `${token}` } });

          if (resultDeleted.data.status) {
            navigate("/vehicle-variant-specification");
            toast.success(resultDeleted?.data?.response_message);
          } else {
            toast.error(resultDeleted?.data?.response_message);
          }
          // if (reloadId.length === 0) {
          //   // window.location.replace(`http://localhost:3000/tag`)
          //   // window.location.replace(`${process.env.REACT_APP_BASE_URL}tag`)
          // } else {
          //   // window.location.replace(`http://localhost:3000/view/tag/${reloadId[0]}`)
          //   // window.location.replace(`${process.env.REACT_APP_BASE_URL}view/tag/${reloadId[0]}`)
          //   navigate(`/view/Variant_specification/${reloadId[0]}`)

          // }
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };
  useEffect(() => {
    Dropdown_Name()
    Getview()
  }, [])
  // console.log('DropDropValue >>>>', DropDropValue)
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3><Link to="/vehicle-variant-specification" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Variant Specification View</h3>
        <div className="page-heading-right">
          <SelectPicker data={DropDropValue} defaultValue={Number(id.id)} value={Number(id.id)} cleanable={false} className="wv-200 my-1 ms-3" onChange={(e) => selectpickerData(e)} placeholder="Select Categories" placement="bottomEnd" />
          {userPermission["Vehicle-info2-Variant-Specification"]?.edit==1&&<Link to={`/vehicle-variant-specification/edit/${id.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
          </Link>}
          {userPermission["Vehicle-info2-Variant-Specification"]?.delete==1&&<Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt"></i></Button>
        }</div>
      </div>
      <div className='page-content'>
        {
          Data?.length && Data?.map(item => (
            <Card className='mb-4'>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Category</p><span>{item?.vehicleInformatiomDetails?.CategoriesDeatils?.category_name}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Brand</p><span>{item?.vehicleInformatiomDetails?.BrandDeatils?.name}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Vehicle Name</p><span>{item?.vehicleInformatiomDetails?.model_name}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Specification</p><span>{item?.VariantSpecificationDetails?.name}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Specification Name</p><span>{item?.name}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Specification Value</p><span>{item?.value}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Key Specification</p><span>{item?.KeySpecificationDetils?.name || ''}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Is Feature</p><span>{item?.is_feature == 1 ? "On" : "Off"}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Is Specification</p><span>{item?.is_specification == 1 ? "On" : "Off"}</span>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Is Overview</p><span>{item?.show_overview == 1 ? "On" : "Off"}</span>
                    </div>
                  </Col>

                </Row>

              </Card.Body>
            </Card>
          ))
        }
      </div>
    </Layout>
  )
}

export default VehicleVariantSpecificationView