import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Card, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import { API } from "../../App";
import { toast } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";

var Page_array = [];
const Tags = () => {
  const token = Cookies.get("fmljwt");
  const [Data, setData] = useState([])
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([])
  const [totalPageCount, setTotalPageCount] = useState('')
  const [isLoading, setIsLoading] = useState(false);


  const GetData = async () => {
    setIsLoading(true)
    try {
      const result = await API.post("/get-tags", { limit: size, page: current }, { headers: { authorization: `${token}` } })
      setData(result.data.data)
      setTotalPageCount(result.data.totalcount)
      PageGetData()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }


  }

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(totalPageCount / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="paggination-btn">Previous</button>;
    }
    if (type === "next") {
      return <button className="paggination-btn">Next</button>;
    }
    return originalElement;
  };

  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber?.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  let count = 10
  let swalCountdownInterval
  const DeleteData = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
         let result =  await API.post(`/detete-tag/${id}`, {}, { headers: { authorization: `${token}` } });
         if(result.data.status){
           GetData();
           setCurrent(1)
          toast.success(result.data.response_message)
         }else{
          toast.success(result.data.response_message)
         }
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
          setCurrent(1)
        }
      });
  };

  const handleLive=async(id)=>{
    setIsLoading(true)
    try {
        const Result = await API.post("/news_headline_live", {id}, { headers: { authorization: `${token}` } })
        if(Result.data.status){
          toast.success(Result.data.message)
      }else{
      toast.error(Result.data.message)
      }
    } catch (error) {
        setIsLoading(false)
    } finally {
        setIsLoading(false)
    }

}
  useEffect(() => {
    GetData()
  }, [current, size])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Tags</h3>
        <div className="page-heading-right">
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            defaultValue={10}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
          <Link to="/tags/add" className="my-1 ms-3">
            <Button variant="primary" value="create">Add New</Button>
          </Link>
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body className="position-relative">
            {isLoading && <div className="loader table-loader" ></div>}
            <Table bordered responsive>
              <thead>
                <tr>
                  <th width="5%" className="text-center">No</th>
                  <th width="75%">Name</th>
                  <th width="10%" className='text-center'>Tag Live</th>
                  <th width="10%" className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  Data?.map((val, i) => {
                    return (
                      <tr>
                        <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                        <td>{val.name}</td>
                        <td className="text-center"><Button variant="info px-3 " size="sm" onClick={()=>handleLive(val.id)}>Live</Button></td>
                        <td className='text-center'>
                      

                          <Link to={`/tags/view/${val.id}`}>
                            <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                          </Link>
                          <Button variant="outline-danger" onClick={() => DeleteData(val.id)} size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
              {
                isLoading == false && totalPageCount === 0 ? <tr>
                  <td colSpan="100%" className="p-0">
                    <div className='no-found'>
                      <img src="../../not-found/image.svg" />
                      <p>No Found Tags</p>
                    </div>
                  </td>
                </tr> : ""
              }
            </Table>
            {totalPageCount > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={totalPageCount}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                  itemRender={PrevNextArrow}
                  onShowSizeChange={PerPageChange}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default Tags