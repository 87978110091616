import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { API } from '../../App';
import Cookies from "js-cookie";
import $ from 'jquery'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';


const NewsView = () => {
    const { userPermission, token } = useContext(AuthContext)
    const params = useParams()
    const [NewsData, setNewsData] = useState([])
    const [id, setid] = useState({ id: params.id });
    const [Data, setData] = useState({
        id: "",
        news_id: "",
    })
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);

    const ShowData = async (id) => {
        setIsLoading(true)
        try {
            const Form = new FormData()
            Form.append('id', params.id)
            const Result = await API.post(`/get-news/${params.id}`, {}, { headers: { authorization: `${token}` } })
            setNewsData(Result.data.data.news.replaceAll(",", "\n"))
            setData({
                id: Result.data.data.id,
                news_id: Result.data.data.NewsHeadLineTitle.id,
            })
            setIsLoading(false)
            setid({ id: Result.data.data.id })
        } catch (error) {
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    let count = 10
    let swalCountdownInterval
    const Deleteproject = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    // const ind = reloadId.indexOf(Data.id)
                    // reloadId.splice(ind, 1)
                    const result = await API.post(`/detete-news/${params.id || id || Data.id}`, {}, { headers: { authorization: `${token}` } });
                    if (result.data.status) {
                        toast.success(result.data.response_message)
                        navigate('/news')
                    }
                    // if (reloadId.length === 0) {
                    //   // window.location.replace(`http://localhost:3000/news_Headline`)
                    //   window.location.replace(`${process.env.REACT_APP_BASE_URL}news_Headline`)
                    // } else {
                    //   // window.location.replace(`http://localhost:3000/view/news_Headline/${reloddId}`)
                    //   window.location.replace(`${process.env.REACT_APP_BASE_URL}view/news_Headline/${reloadId}`)
                    // }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };


    useEffect(() => {
        ShowData()
    }, [])

    return (
        <Layout sidebar={true}>
            {/* {isLoading && <div className="loader" ></div>} */}
            <div className="page-heading">
                <h3><Link to="/news" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link> News View</h3>
                <div className="page-heading-right">
                {userPermission["News"]?.edit==1 &&<Link to={`/news/edit/${id.id}`}>
                        <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
                    </Link>}
                    {userPermission["News"]?.delete==1 &&<Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt"></i></Button>
}
                </div>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <div className="content" dangerouslySetInnerHTML={{ __html: NewsData }}></div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default NewsView