import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { API } from "../../App";
import Layout from "../../layout/Layout";
import $ from "jquery";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Pagination from "rc-pagination";
import { toast } from "react-toastify";
import { AuthContext } from "../../AppContext";

var StateNAmeArray = [];
var Page_array = [];
const DrivingSchoolArea = () => {
  const { userPermission, token } = useContext(AuthContext)
  const [Data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([]);
  const [Sate_nameData, setSate_nameData] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState("");
  const [setsearchTerm, setSetsearchTerm] = useState("");
  const [setCityId, setSetCityId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const GetData = async () => {
    setIsLoading(true);
    try {
      const result = await API.post(
        "/get_driving_school_area",
        {
          limit: size,
          page: current,
          search: setsearchTerm,
          city_id: setCityId,
        },
        { headers: { authorization: `${token}` } }
      );
      setData(result.data.data);
      PageGetData();
      setTotalPageCount(result.data.totalcount);
      State_name_dropdown();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Paggintion Code //
  // const getData1 = (current, pageSize) => {
  //   return Data.slice((current - 1) * pageSize, current * pageSize);
  // };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(totalPageCount / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="paggination-btn">Previous</button>;
    }
    if (type === "next") {
      return <button className="paggination-btn">Next</button>;
    }
    return originalElement;
  };

  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100];
    Page_array = [];
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val });
    });
    setPageHook(Page_array);
  };

  const State_name_dropdown = async () => {
    const Result = await API.post(
      "/get_all_driving_school_city",
      {},
      { headers: { authorization: `${token}` } }
    );
    StateNAmeArray = [];
    StateNAmeArray.push({ label: "Select City", value: "", role: "" });
    Result.data.data.map((val) => {
      StateNAmeArray.push({ label: val.city_name, value: val.id });
    });
    setSate_nameData(StateNAmeArray);
  };

  let count = 10;
  let swalCountdownInterval;
  const DeleteData = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: "../../icon/alert.svg",
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr("disabled", true);
          swalCountdownInterval = setInterval(function () {
            count--;
            if (count < 1) {
              $(".counter").text(`OK`);
              $(".swal2-confirm").attr("disabled", false);
              clearInterval(swalCountdownInterval);
            } else {
              $(".counter").text(`OK (${count})`);
            }
          }, 1000);
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const resultDelete = await API.post(`/delete_driving_school_area/${id}`,{}, {
            headers: { authorization: `${token}` },
          });
          if (resultDelete.data.status) {
            toast.success(resultDelete.data.response_message)
            GetData();
            setCurrent(1)
          } else {
            toast.success(resultDelete.data.response_message)
          }
        } else {
          count = 10;
          clearInterval(swalCountdownInterval);
        }
      });
  };

  // const getFilterAreaData = async () => {
  //   let result = await API.post("/driving_school_area_search", { search: setsearchTerm, city_id: setCityId, limit: size, page: current }, { headers: { authorization: `${token}` } });
  //   setData(result.data.data);
  //   setTotalPageCount(result?.data?.totalcount);
  // }

  useEffect(() => {
    GetData();
  }, [setsearchTerm, setCityId, current, size]);

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Driving School Area</h3>
        <div className="page-heading-right">
          <Form.Control
            type="text"
            name="search"
            id=""
            placeholder="Search Area"
            className="wv-200 my-1 ms-3"
            onChange={(e) => {
              setSetsearchTerm(e.target.value);
              setCurrent(1);
            }}
          // onChange={(e) => setSetsetCityId(e)}
          />
          <SelectPicker
            cleanable={false}
            data={Sate_nameData}
            defaultValue={""}
            placement="bottomEnd"
            className="wv-200 my-1 ms-3"
            placeholder="Select State"
            // value={search[2].search.status}
            onChange={(e) => {
              setSetCityId(e);
              setCurrent(1);
            }}
          // onChange={(e) => Seaching(e, "cityid")}
          />
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            defaultValue={10}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
          {userPermission["Driving-School-Area"]?.add==1&&<Link to="/driving-school-area/add" className="my-1 ms-3">
            <Button variant="primary" value="create">
              Add New
            </Button>
          </Link>}
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body className="position-relative">
            {isLoading && <div className="loader table-loader"></div>}
            <Table bordered responsive>
              <thead>
                <tr>
                  <th width="5%" className="text-center">
                    No
                  </th>
                  <th width="30%">City Name</th>
                  <th width="30%">Area Name</th>
                  {/* <th width="15%" >Other Name</th> */}
                  <th width="25%">Zip Code</th>
                  {/* <th width="10%" >Area Live</th> */}
                  <th width="10%" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {Data?.map((val, i) => {
                  return (
                    <tr>
                      <td className="text-center">
                        {current === 1 ? i + 1 : current * size + i + 1 - size}
                      </td>
                      <td>{val?.cityDetails?.city_name || null}</td>
                      <td>{val?.area_name}</td>
                      {/* <td>{val.other_name}</td> */}
                      <td>{val?.zip_code}</td>
                      {/* <td>1</td> */}
                      <td className="text-center">
                        <Link to={`/driving-school-area/view/${val.id}`}>
                          <Button
                            variant="outline-warning"
                            size="sm"
                            className="me-2 btn-icon"
                          >
                            <i className="bx bx-show"></i>
                          </Button>
                        </Link>
                        {userPermission["Driving-School-Area"]?.delete==1&&<Button
                          variant="outline-danger"
                          onClick={() => DeleteData(val.id)}
                          size="sm"
                          className="btn-icon"
                        >
                          <i className="bx bx-trash-alt"></i>
                        </Button>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {isLoading == false && totalPageCount === 0 ? (
                <tr>
                  <td colSpan="100%" className="p-0">
                    <div className="no-found">
                      <img src="../../not-found/image.svg" />
                      <p>No Found Driving School Area</p>
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </Table>
            {totalPageCount > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={totalPageCount}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                  itemRender={PrevNextArrow}
                  onShowSizeChange={PerPageChange}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default DrivingSchoolArea;
