import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../App";
import Layout from '../../layout/Layout';
import Cookies from "js-cookie";

const ModuleEdit = () => {
  const token = Cookies.get("fmljwt");
  const params = useParams()
  const [validated, setvalidated] = useState(false)
  const navigate = useNavigate()
  const [Data, setData] = useState([{
    name: "",
    path: "",
  }])

  const Getview = async (Eid) => {
    const result = await API.post(`/permission/viwe-permission/${params.id}`, {}, { headers: { authorization: `${token}` } });
    setData({
      name: result.data.data.display_name,
      path: result.data.data.name,
    });
  };

  useEffect(() => {
    Getview()
  }, [])

  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value })
  }

  const Submite = async () => {
    if (Data.name == "" || Data.path == "") {
      setvalidated(true)
    } else {
      const Result = await API.post(`/permission/update-module/${params.id}`, { name: Data.path }, { headers: { authorization: `${token}` } })
      if (Result) {
        toast.success("Data Update successfully");
        navigate(`/module/view/${params.id}`)
      }
    }
  }

  return (
    <>
      <Layout sidebar={true}>
        <div className="page-heading">
          <h3>Module Edit</h3>
          <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
            <Breadcrumb.Item>
              <Link to="/home">
                <i className="bx bx-home-alt me-2 fs-5"></i> Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/Module">Module List</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Module Edit</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="page-content">
          <Form noValidate validated={validated}>
            <Card className="mb-4">
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <Form.Label htmlFor="name">Module Name</Form.Label>
                    <Form.Control type="text" className="my-2" name="name" value={Data.name} onChange={SaveData} required />
                    <Form.Control.Feedback type="invalid">
                      Module Name Field Is Require
                    </Form.Control.Feedback>
                  </Col>
                  <Col md={4}>
                    <Form.Label htmlFor="name">Route</Form.Label>
                    <Form.Control type="text" className="my-2" name="path" value={Data.path} onChange={SaveData} required />
                    <Form.Control.Feedback type="invalid">
                      Route Field Is Require
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" className="me-3" onClick={Submite} >
                  Save
                </Button>
                <Link to={`/module/view/${params.id}`}>
                  <Button variant="secondary">Cancel</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Form>
        </div>
      </Layout>
    </>
  )
}

export default ModuleEdit