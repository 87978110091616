import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Cookies from 'js-cookie'
import { API } from '../../App';
import Swal from "sweetalert2";
import $ from 'jquery'
import { toast } from "react-toastify";
import moment from 'moment/moment';
import { AuthContext } from '../../AppContext';

const ShortsView = () => {
  const token = Cookies.get("fmljwt");
  const { userPermission } = useContext(AuthContext)

  const navigate = useNavigate()

  const { id } = useParams()
  const [shorts, setShorts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log('state', id)


  const getShorts = async (id) => {
    setIsLoading(true);
    const Form = new FormData()
    Form.append("short_id", id)
    try {
      const result = await API.post("/view-short", Form, { headers: { authorization: `${token}` } })
      console.log('result.data', result.data)
      setShorts(result?.data?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  let count = 10
    let swalCountdownInterval
    const DeleteData = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const formdata = new FormData()
                    formdata.append("short_id", id)
                    const response = await API.post(`/delete-short`, formdata, { headers: { authorization: `${token}` } });
                    if (response?.status) {
                        toast.success("Data Delete successfully")
                        navigate("/shorts")
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

  useEffect(() => {
    id && getShorts(id)
  }, [id])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3><Link to="/shorts" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Shorts Video View</h3>
        <div className="page-heading-right">
        {userPermission["Shorts"]?.edit == 1 &&<Link to={`/shorts/edit/${id}`}>
            <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
            </Link>}
            {userPermission["Shorts"]?.delete == 1 &&<Button variant="danger ms-3 my-1 btn-icon-lg" onClick={() => DeleteData(id)} type="button"><i className="bx bx-trash-alt"></i></Button>}
        </div>
      </div>
      <div className='page-content'>
        <Card>
          <Card.Body className=''>
            <Row>
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Title</p>
                      <span>{shorts?.title ? shorts?.title : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Count (Like)</p>
                      <span>{shorts?.likes ? shorts?.likes : 0}</span>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>English</p>
                      <span>{shorts?.description_en ? shorts?.description_en : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Gujarati</p>
                      <span>{shorts?.description_gu ? shorts?.description_gu : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Hindi</p>
                      <span>{shorts?.description_hi ? shorts?.description_hi : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Marathi</p>
                      <span>{shorts?.description_mr ? shorts?.description_mr : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Tamil</p>
                      <span>{shorts?.description_ta ? shorts?.description_ta : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Telugu</p>
                      <span>{shorts?.description_te ? shorts?.description_te : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Kannada</p>
                      <span>{shorts?.description_kn ? shorts?.description_kn : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Bengali</p>
                      <span>{shorts?.description_bn ? shorts?.description_bn : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Punjabi</p>
                      <span>{shorts?.description_pa ? shorts?.description_pa : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Malayalam</p>
                      <span>{shorts?.description_ml ? shorts?.description_ml : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Odia</p>
                      <span>{shorts?.description_or ? shorts?.description_or : "-"}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Date</p>
                      <span>{moment(shorts?.created_at).format('DD-MM-YYYY')}</span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='mb-4'>
                      <p className='mb-0 fw-bold'>Status</p>
                      <span>{shorts?.status == 1 ? "On" : "Off"}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Video</p>
                  <video width="300" height="500" controls >
                    {shorts?.short_link && <source src={`${shorts?.short_link}`} type="video/mp4"  />}
                  </video>
                </div>
              </Col>

            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default ShortsView