import React, { useState } from "react";
import { Row, Col, Card, Button, Form, InputGroup, Image, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { API } from "../../App";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { SelectPicker } from "rsuite";

const RCDLinfoEdit = () => {
    const token = Cookies.get("fmljwt");
    const params = useParams();
    const navigate = useNavigate();
    const [validated, setvalidated] = useState(false)
    const [en, seten] = useState()
    const [hi, sethi] = useState()
    const [mr, setmr] = useState()
    const [gu, setgu] = useState()
    const [kn, setkn] = useState()
    const [ta, setta] = useState()
    const [te, sette] = useState()
    const [bn, setbn] = useState()
    const [ml, setml] = useState()
    const [or, setor] = useState()
    const [pa, setpa] = useState()
    const [thumb_image, setthumb_image] = useState()
    const [Data, SetData] = useState({
        id: "",
        type: "",
        title: "",
        en: "",
        hi: "",
        mr: "",
        gu: "",
        kn: "",
        ta: "",
        te: "",
        bn: "",
        ml: "",
        or: "",
        pa: "",
        thumb_image: "",
        status: "",
    });
    console.log('SetData >>>>>', Data)
    const [isLoading, setIsLoading] = useState(false);
    const typeOptions = [
        { value: "1", label: "DL" },
        { value: "2", label: "RC" },
    ]

    const Getview = async (Eid) => {
        // setIsLoading(true)
        const result = await API.post(`/get_rcdl_info/${params.id}`, {}, { headers: { authorization: `${token}` } });
        SetData({
            id: result.data.data.id,
            type: result.data.data.type,
            title: result.data.data.title,
            en: result.data.data.en,
            hi: result.data.data.hi,
            mr: result.data.data.mr,
            gu: result.data.data.gu,
            kn: result.data.data.kn,
            ta: result.data.data.ta,
            te: result.data.data.te,
            bn: result.data.data.bn,
            ml: result.data.data.ml,
            or: result.data.data.or,
            pa: result.data.data.pa,
            thumb_image: result.data.data.thumb_image,
            status: result.data.data.status,
        });
        // if (result.data.status) {
        //     // setIsLoading(false)
        // } else {
        //     toast(result.data.response_message)
        // }
    };

    const enhendler = (e) => {
        seten(e.target.files[0])
    };
    const hihendler = (e) => {
        sethi(e.target.files[0])
    };
    const mrhendler = (e) => {
        setmr(e.target.files[0])
    };
    const knhendler = (e) => {
        setkn(e.target.files[0])
    };
    const guhendler = (e) => {
        setgu(e.target.files[0])
    };
    const tahendler = (e) => {
        setta(e.target.files[0])
    };
    const tehendler = (e) => {
        sette(e.target.files[0])
    };
    const bnhendler = (e) => {
        setbn(e.target.files[0])
    };
    const mlhendler = (e) => {
        setml(e.target.files[0])
    };
    const orhendler = (e) => {
        setor(e.target.files[0])
    };
    const pahendler = (e) => {
        setpa(e.target.files[0])
    };
    const thumb_imagehendler = (e) => {
        setthumb_image(e.target.files[0])
    };
    const SaveData = async (e) => {
        SetData({ ...Data, [e.target.name]: e.target.value })
    }

    const Statushendler = (e) => {
        const Result = e === true ? 1 : 0;
        SetData({
            type: Data.type,
            title: Data.title,
            en: Data.en,
            hi: Data.hi,
            mr: Data.mr,
            gu: Data.gu,
            kn: Data.kn,
            ta: Data.ta,
            te: Data.te,
            bn: Data.bn,
            ml: Data.ml,
            or: Data.or,
            pa: Data.pa,
            thumb_image: Data.thumb_image,
            status: Result,
        });
    };


    const Submite = async () => {
        if (Data.type === "" || Data.title === "") {
            setvalidated(true)
        } else {
            setIsLoading(true)
            try {
                const Formdata = new FormData();
                Formdata.append("type", Data.type);
                Formdata.append("title", Data.title);
                Formdata.append("status", Number(Data.status));
                console.log('typeof en', typeof en)
                console.log('typeof hi', typeof hi)
                typeof en == 'object' && Formdata.append("en", en);
                typeof hi == 'object' && Formdata.append("hi", hi);
                typeof mr == 'object' && Formdata.append("mr", mr);
                typeof kn == 'object' && Formdata.append("kn", kn);
                typeof ta == 'object' && Formdata.append("ta", ta);
                typeof te == 'object' && Formdata.append("te", te);
                typeof bn == 'object' && Formdata.append("bn", bn);
                typeof ml == 'object' && Formdata.append("ml", ml);
                typeof or == 'object' && Formdata.append("or", or);
                typeof pa == 'object' && Formdata.append("pa", pa)
                typeof thumb_image == 'object' && Formdata.append("thumb_image", thumb_image);

                const result = await API.post(`/update_rc_dl_info/${params.id}`, Formdata, { headers: { authorization: `${token}` } });
                if (result.data.status) {
                    setIsLoading(false)
                    toast.success("Data Update successfully");
                    navigate(`/dl-rc-info/view/${params.id}`)
                } else {
                    toast.error(result.data.response_message);
                }
            } catch (error) {
                setIsLoading(true)
            } finally {
                setIsLoading(true)
            }

        }
    }
    useEffect(() => {
        Getview()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>DL / RC Info Edit</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/dl-rc-info">RC-DL Info</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>RC-DL Info Edit</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validated}>
                    <Card className="mb-4">
                        <Card.Body className="position-relative">
                            {isLoading && <div className="loader" ></div>}
                            <Row>
                                <Col md={4}>
                                    <Form.Label htmlFor="type">Type</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={typeOptions}
                                        searchable={false}
                                        name="type"
                                        value={Data.type}
                                        className="my-2"
                                        block
                                        placeholder="Select type "
                                        onChange={(e) => SetData({ ...Data, type: e })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Type Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={8}>
                                    <Form.Label htmlFor="title">Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        className="my-2"
                                        value={Data.title}
                                        onChange={SaveData}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Title Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="english">English</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="en"
                                            onChange={enhendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.en}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="hindi">Hindi</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="hi"
                                            onChange={hihendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.hi}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="marathi">Marathi</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="mr"
                                            onChange={mrhendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.mr}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="gujarati">Gujarati</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="gu"
                                            onChange={guhendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.gu}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="tamil">Tamil</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="ta"
                                            onChange={tahendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.ta}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="kannada">Kannada</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="kn"
                                            onChange={knhendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.kn}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="telugu">Telugu</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="te"
                                            onChange={tehendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.te}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="bangali">Bangali</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="bn"
                                            onChange={bnhendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.bn}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="panjabi">Panjabi</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="pa"
                                            onChange={pahendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.pa}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="odisha">Odisha</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="or"
                                            onChange={orhendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.or}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="malyalam">Malyalam</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="ml"
                                            onChange={mlhendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.ml}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={4}>
                                    <Form.Label htmlFor="thumbimage">Thumb Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="thumb_image"
                                            onChange={thumb_imagehendler}
                                        />
                                        <InputGroup.Text>
                                            <Image
                                                className="hv-30 rounded-3"
                                                src={Data.thumb_image}
                                            ></Image>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block">
                                        Status
                                    </Form.Label>
                                    <Switch
                                        checked={Data.status === 1 ? true : false}
                                        onChange={Statushendler}
                                        name="status"
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch my-2"
                                        uncheckedIcon={
                                            <div className="react-switch-off">OFF</div>
                                        }
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" onClick={Submite} className="me-3">Save</Button>
                            <Link to={`/dl-rc-info/view/${params.id}`}>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    )
}

export default RCDLinfoEdit