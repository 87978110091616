import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import { API } from '../../App';
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';

var reloadId = [];
const TrafficRuleView = () => {
  const { userPermission, token } = useContext(AuthContext)

  const params = useParams();
  const [id, setid] = useState({ id: params.id });
  const [Data, SetData] = useState({
    id: "",
    state_code: "",
    state_name: "",
    title: "",
    sub_title: "",
    content: "",
    disclaimer: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const [DropDropValue, setDropDropValue] = useState([])
  const Dropdown_Name = async () => {
    const Result = await API.post("/alloffence", {}, { headers: { authorization: `${token}` } })
    setDropDropValue(Result.data.data.map((val, i) => {
      return { label: val.offence, value: val.id }
    }))
  }

  const Getview = async (Eid) => {
    setIsLoading(true)
    try {
      const result = await API.post(`/edit_traffic_rules/${Eid !== "" ? Eid : id.id}`, {}, { headers: { authorization: `${token}` } });
      SetData({
        id: result.data.data.id,
        traffic_state_id: result.data.data.traffic_state_details.state_name,
        Offence: result.data.data.offence,
        Penalty: result.data.data.penalty,

      });
      setIsLoading(false)

      setid({ id: result.data.data.id })
      Dropdown_Name()
    } catch (error) {
      toast.error(error.message)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  };

  const selectpickerData = (e) => {
    setid({ id: e });
    Getview(e);
    navigate(`/traffic-rule/view/${e}`)
  };

  useEffect(() => {
    Getview("")
  }, [])


  let count = 10
  let swalCountdownInterval
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data.id)
          reloadId.splice(ind, 1)
          const resultDelete = await API.post(`/delete_traffic_rule/${id}`, {}, { headers: { authorization: `${token}` } });
          if (resultDelete?.data?.status == true) {
            navigate('/traffic-rule')
            toast.success(resultDelete?.data?.response_message)
          } else {
            toast.error(resultDelete?.data?.response_message)
          }
          // if (reloadId.length === 0) {
          //   // window.location.replace(`http://localhost:3000/Traffic_rule`)
          //   window.location.replace(`${process.env.REACT_APP_BASE_URL}Traffic_rule`)
          // } else {
          //   // window.location.replace(`http://localhost:3000/view/Traffic_rule/${reloadId[0]}`)
          //   window.location.replace(`${process.env.REACT_APP_BASE_URL}view/Traffic_rule/${reloadId[0]}`)
          // }
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3><Link to="/traffic-rule" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Traffic Rule View</h3>
        <div className="page-heading-right">
          <SelectPicker data={DropDropValue} value={id.id} cleanable={false} className="wv-200 my-1 ms-3" onChange={(e) => selectpickerData(e)} placeholder="Select Offence" placement="bottomEnd" />
         {userPermission["Traffic-Rules"]?.edit==1 && <Link to={`/traffic-rule/edit/${id.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">{params.id ? "Edit" : "Add"}</Button>
          </Link>}
          {userPermission["Traffic-Rules"]?.delete==1 && <Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt"></i></Button>
}
        </div>
      </div>
      <div className='page-content'>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>State Name</p><span>{Data.traffic_state_id}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Offence</p><span>{Data.Offence}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Penalty</p><span>{Data.Penalty}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default TrafficRuleView