import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
import { API } from '../../App';
import Swal from "sweetalert2";
import $ from "jquery";
import Cookies from "js-cookie";
import Layout from '../../layout/Layout';
import { toast } from 'react-toastify';

var DropDownArr = []
var reloadId = [];
const ModuleView = () => {
  const token = Cookies.get("fmljwt");
  const navigate = useNavigate();
  const params = useParams();
  const [variableData, setvariableData] = useState([]);
  const [id, setid] = useState({ id: params.id });
  const [Data, SetData] = useState({
    id: "",
    name: "",
    path: "",
  });
  const [DropDropValue, setDropDropValue] = useState([])
  const Dropdown_Name = async () => {
    const Result = await API.post("/permission/get-permission", {}, { headers: { authorization: `${token}` } })
    DropDownArr = []
    reloadId = []
    // DropDownArr.push({label:"Select Title" , value:"" })
    Result?.data?.data?.map((val, i) => {
      DropDownArr.push({ label: val.name, value: val.id })
      reloadId.push(val.id)
    })
    setDropDropValue(DropDownArr)
  }

  const Getview = async (Eid) => {
    const result = await API.post(`/permission/viwe-permission/${Eid !== "" ? Eid : id.id}`, {}, { headers: { authorization: `${token}` } });
    SetData({
      id: result.data.data.id,
      name: result.data.data.display_name,
      path: result.data.data.name,
    });

    setid({ id: result.data.data.id });
  };

  const selectpickerData = (e) => {
    setid({ id: e });
    navigate(`/module/view/${e}`)
    Getview(e);
  };

  useEffect(() => {
    Dropdown_Name()
    Getview("")
  }, [])


  let count = 10
  let swalCountdownInterval
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data.id)
          reloadId.splice(ind, 1)
          const formdata = new FormData()
          formdata.append("id", Data.id)
          const result = await API.post(`permission/delete-permission/${params?.id}`, formdata, { headers: { authorization: `${token}` } });
          if (result.data.status) {
            navigate(`/module`)
            toast.success('Module Deleted Succesfully!!')
          } else {
            toast.success(result.data.response_message)
          }
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };
  return (
    <>
      <Layout sidebar={true}>
        <div className="page-heading">
          <h3>
            <Link to="/module" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>
             Module View
          </h3>
          <div className="page-heading-right">
            <SelectPicker data={DropDropValue} defaultValue={id.id} value={id.id} cleanable={false} className="wv-200 my-1 ms-3" onChange={(e) => selectpickerData(e)} placeholder="Select Categories" placement="bottomEnd" />
            <Link to={`/module/edit/${id.id}`}>
              <Button variant="primary ms-3 my-1" value="edit" >
                Edit
              </Button>
            </Link>
            <Button variant="danger ms-3 my-1 btn-icon-lg" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt" ></i></Button>
          </div>
        </div>
        <div className="page-content">
          <Card>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <div className="mb-4">
                    <p className="mb-0 fw-bold">Module Name</p>
                    <span>{Data.name}</span>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-4">
                    <p className="mb-0 fw-bold">Route</p>
                    <span>{Data.path}</span>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Layout>
    </>
  )
}

export default ModuleView