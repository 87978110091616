import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import Switch from 'react-switch';
import Fancybox from "../../Component/FancyBox";
import { SelectPicker } from "rsuite";
import { API } from "../../App";
import Cookies from 'js-cookie'
import Swal from "sweetalert2";
import $ from 'jquery'
import { toast } from "react-toastify";
import moment from "moment";
import { AuthContext } from "../../AppContext";


const PageSize = [10, 25, 50, 100].map((val, index) => ({ label: val, value: val }));

const Shorts = () => {
    const token = Cookies.get("fmljwt");
  const { userPermission } = useContext(AuthContext)

    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);
    const [allShorts, setAllShorts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate()

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const paginationData = (page) => {
        setCurrent(page);
    };

    const getShortsList = async () => {
        setIsLoading(true);
        const Form = new FormData()
        Form.append("page", current)
        Form.append("limit", size)
        try {
            const result = await API.post("/get-short", Form, { headers: { authorization: `${token}` } })
            console.log('result.data', result.data)
            setAllShorts(result?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    let count = 10
    let swalCountdownInterval
    const DeleteData = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const formdata = new FormData()
                    formdata.append("short_id", id)
                    const response = await API.post(`/delete-short`, formdata, { headers: { authorization: `${token}` } });
                    if (response?.status) {
                        toast.success("Data Delete successfully")
                    }
                    getShortsList();
                    setCurrent(1)
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };
    const Togglechange = async (id, e, name) => {
        var status;
        const Form = new FormData();
        if (name === "status") {
            status = e === true ? 1 : 0;
        }
        Form.append("short_id", id);
        Form.append("status", status);
        // Form.append("name", name);
        const result = await API.post(`/update-shorts-status`, Form, {
            headers: { authorization: `${token}` },
        });
        if (result) {
            toast.success("Status Update Successfully");
            getShortsList();
        }
    };

    useEffect(() => {
        getShortsList()
    }, [current, size])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Shorts Video</h3>
                <div className="page-heading-right">
                    <SelectPicker
                        cleanable={false}
                        data={PageSize}
                        searchable={false}
                        defaultValue={10}
                        value={size}
                        placement="bottomEnd"
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                    {userPermission["Shorts"]?.add == 1 && <Link to="/shorts/add" className="my-1 ms-3">
                        <Button variant="primary" value="create">Add New</Button>
                    </Link>}
                </div>
            </div>
            <div className="page-content">
                <Card>
                    <Card.Body className="position-relative">
                        {isLoading && <div className="loader table-loader"></div>}
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="17%">Title</th>
                                    <th width="30%">Description</th>
                                    <th width="8%" className="text-center">Video</th>
                                    <th width="8%" className="text-center">Count (Like)</th>
                                    <th width="8%" className="text-center">Date</th>
                                    <th width="7%" className="text-center">Status</th>
                                    <th width="7%" className="text-center">Shorts Live</th>
                                    <th width="10%" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allShorts?.data?.length > 0 ? allShorts?.data?.map((item, index) => {
                                    return (<tr key={index}>
                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                        <td>{item?.title ? item?.title : "-"}</td>
                                        <td>{item?.description_en ? item?.description_en : "-"}</td>
                                        <td className="text-center">
                                            <Fancybox >
                                                <a className="me-2" href={`${item?.short_link}`} data-fancybox="gallery" >
                                                    <Button variant="outline-primary" size="sm" className="btn-icon"><i className='bx bx-video-recording' ></i></Button>
                                                </a>
                                            </Fancybox>
                                        </td>
                                        <td className="text-center">{item?.likes ? item?.likes : 0}</td>
                                        <td className="text-center">{item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY') : "- "}</td>
                                        <td className='text-center'>
                                            <Switch
                                                onChange={(e) =>
                                                    Togglechange(item.id, e, "status")
                                                }
                                                checked={item?.status == 1 ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                disabled={userPermission["Shorts"]?.edit != 1}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">ON</div>
                                                }
                                            />
                                        </td>
                                        <td className="text-center">
                                            <Button variant="info px-3" size="sm">Live</Button>
                                        </td>
                                        <td className='text-center'>
                                            {userPermission["Shorts"]?.view == 1 && <Link
                                                to={`/shorts/view/${item?.id}`}
                                            >
                                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon" ><i className='bx bx-show'></i></Button>
                                            </Link>}
                                            {userPermission["Shorts"]?.delete == 1 && <Button variant="outline-danger" size="sm" onClick={() => DeleteData(item.id)} className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>}
                                        </td>
                                    </tr>)
                                })
                                    : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found Shorts Video</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>
                        <div className="pagination-custom">
                            {allShorts?.totalCount > size && <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={allShorts?.totalCount}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Layout >
    )
}

export default Shorts