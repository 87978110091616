import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import {
    Row,
    Col,
    Card,
    Button,
    Form,
    InputGroup,
    Breadcrumb,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Switch from "react-switch";
import Fancybox from "../../Component/FancyBox";
import { useFormik } from "formik";
import { API } from "../../App";
import Cookies from 'js-cookie'
import { toast } from "react-toastify";
import * as yup from "yup";


const ShortsAdd = () => {
    const token = Cookies.get("fmljwt");
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams()
    const [shorts, setShorts] = useState([]);
    const navigate = useNavigate()


    const getShorts = async (id) => {
        setIsLoading(true);
        const Form = new FormData()
        Form.append("short_id", id)
        try {
            const result = await API.post("/view-short", Form, { headers: { authorization: `${token}` } })
            console.log('result.data', result.data)
            setShorts(result?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const validationSchema = yup.object({
        title: yup.string().required("Please Enter The Title"),
        video: yup.mixed().required("Please Select The Vodeo"),
    });
    const initialValues = {
        title: shorts?.title || "",
        url: shorts?.url || "",
        video: shorts?.short_link || "",
        en: shorts?.description_en || "",
        gu: shorts?.description_gu || "",
        hi: shorts?.description_hi || "",
        mr: shorts?.description_mr || "",
        ml: shorts?.description_ml || "",
        kn: shorts?.description_kn || "",
        or: shorts?.description_or || "",
        pa: shorts?.description_pa || "",
        ta: shorts?.description_ta || "",
        te: shorts?.description_te || "",
        bn: shorts?.description_bn || "",
        status: shorts?.status || 0
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values => {
            handleAddShorts(values)

        })
    })
    const handleAddShorts = async (values) => {
        setIsLoading(true);
        const Form = new FormData()
        Form.append("title", values?.title)
        Form.append("description_en", values?.en)
        Form.append("description_hi", values?.hi)
        Form.append("description_mr", values?.mr)
        Form.append("description_gu", values?.gu)
        Form.append("description_kn", values?.kn)
        Form.append("description_ta", values?.ta)
        Form.append("description_te", values?.te)
        Form.append("description_bn", values?.bn)
        Form.append("description_ml", values?.ml)
        Form.append("description_or", values?.or)
        Form.append("description_pa", values?.pa)
        Form.append("status", values?.status)
        Form.append("url", values?.url)
        Form.append("short_vedio", values?.video)
        id && Form.append("short_id", id)
        try {
            const result = await API.post(`${id ? "/update-short" : "/create-short"}`, Form, { headers: { authorization: `${token}` } })
            if (result.status) {
                toast.success(`Data ${id ? "Update" : "Saved"} successfully`);
                navigate("/shorts")
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
 
    useEffect(() => {
        id && getShorts(id)
    }, [id])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                {isLoading && <div className="loader" ></div>}
                <h3>Shorts Video Add</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item>
                        <Link to="/">
                            <i className="bx bx-home-alt me-2 fs-5"></i> Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/shorts">Shorts Video </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active> Shorts Video Add</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" className="my-2" onChange={formik?.handleChange} value={formik.values.title} name="title" />
                                    {formik.errors.title && formik.touched.title ? (
                                        <p className="form-error">{formik.errors.title}</p>
                                    ) : null}
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control type="text" className="my-2" onChange={formik?.handleChange} value={formik.values.url} name="url" />
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Video</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control type="file" onChange={(e) => formik.setFieldValue("video", e.target.files[0])} name="video" accept="video/mp4,video/x-m4v,video/*" />
                                        <InputGroup.Text>
                                            <Fancybox>
                                                <a href={`${formik.values.video}`} data-fancybox="gallery">
                                                    <Button variant="outline-primary" size="sm" className="btn-icon"><i className='bx bx-video-recording' ></i></Button>
                                                </a>
                                            </Fancybox>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {formik.errors.video && formik.touched.video ? (
                                        <p className="form-error">{formik.errors.video}</p>
                                    ) : null}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>English</Form.Label>
                                    <Form.Control as="textarea" onChange={formik?.handleChange} value={formik.values.en} name="en" rows={1} className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Gujarati</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.gu} name="gu" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Hindi</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.hi} name="hi" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Marathi</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.mr} name="mr" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Tamil</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.ta} name="ta" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Telugu</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.te} name="te" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Kannada</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.kn} name="kn" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Bengali</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.bn} name="bn" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Punjabi</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.pa} name="pa" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Malayalam</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.ml} name="ml" className="my-2" />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Odia</Form.Label>
                                    <Form.Control as="textarea" rows={1} onChange={formik?.handleChange} value={formik.values.or} name="or" className="my-2" />
                                </Col>
                                <Col md={2}>
                                    <Form.Label className="d-block" >Status</Form.Label>
                                    <Switch
                                        onChange={(e) => formik?.setFieldValue("status", e ? 1 : 0)}
                                        checked={formik.values.status == 1 ? true : false}
                                        offColor="#C8C8C8"
                                        name="status"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch my-2"
                                        uncheckedIcon={<div className="react-switch-off">OFF</div>}
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" type="submit" className="me-3">Save</Button>
                            <Link to="/shorts">
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    );
};

export default ShortsAdd;
