import React, { useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Form, Card, Row, Col, Button, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SelectPicker from 'rsuite/SelectPicker';
import Select from "react-select"
import { addServiceProviderCity, getServiceProviderDropdown, viewServiceProviderCity } from '../../service/apis';
import { toast } from "react-toastify";

const ServiceProviderCityAdd = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [idValidated, setIdValidated] = useState(false)
    const [isAllCity, setIsAllCity] = useState(true)
    const [serviceProviderCity, setServiceProviderCity] = useState([])
    const [serviceProviderDropdownCity, setServiceProviderDropdownCity] = useState([])
    const [selectedCitys, setSelectedCitys] = useState([])
    const [validation, setValidation] = useState({
        service_provider_id: "Please Select Service ProviderId",
        affiliation_services_id: "Please Select Service",
        cities: "Please Select City",
    })

    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        fetchServiceProviderDropdown()
    }, [])
    useEffect(() => {
        params.id && fetchViewServiceProviderCity()
    }, [params?.id])


    //Form Field
    const serviceProvider = serviceProviderDropdownCity?.service_provider?.map(
        item => ({ label: item?.provider, value: item?.id })
    );
    const service = serviceProviderDropdownCity?.affilation_service?.map(
        item => ({ label: item?.services, value: item?.id })
    );
    const citys = serviceProviderDropdownCity?.affilation_city?.map(
        item => ({ label: item?.city, value: item?.id })
    );

    useEffect(() => {
        if (serviceProviderCity?.city_name) {
            const updatedVehicleName = serviceProviderCity?.city_name?.map((item) => ({
                label: item.city,
                value: item?.id
            }));

            setSelectedCitys((prevVehicleName) => [...updatedVehicleName]);
        }
    }, [serviceProviderCity?.city_name]);

    //api 
    const fetchServiceProviderDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getServiceProviderDropdown()
            setServiceProviderDropdownCity(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchViewServiceProviderCity = async () => {
        setIsLoading(true);
        try {
            const response = await viewServiceProviderCity(params.id)
            setServiceProviderCity(response?.data?.data)
            setIsAllCity(response?.data?.data?.is_all_city)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    //on change
    const handleChange = (e, name) => {
        const updatedData = { ...serviceProviderCity };
        if (name == "pincode_id") {
            const input = e;
            const regex = /^[0-9,]+$/;
            if (regex.test(input) || input === "") {
                updatedData[name] = e
            }
        } else {

            updatedData[name] = e
        }

        setServiceProviderCity(updatedData)
        setValidation({ ...validation, [name]: "" })
        // setIdValidated(true)
    }
    const handleSelectCitys = (e) => {
        const city = []
        e.map((item) => city.push({ city: item?.label, id: item?.value }))
        const updatedData = { ...serviceProviderCity };
        updatedData.city_name = city
        setServiceProviderCity(updatedData)
        city.length > 0 ? setValidation({ ...validation, cities: "" }) : setValidation({ ...validation, cities: "Please Select City" });
    }





    const handleSave = (event) => {
        event.preventDefault();
        setIdValidated(true)
        if (
            (serviceProviderCity?.service_provider_id &&
                serviceProviderCity?.affiliation_services_id)
        ) {
            let checkStatus
            if (isAllCity) {
                console.log("first")
                checkStatus = true
            } else {
                if (selectedCitys && selectedCitys.length) {
                    checkStatus = true
                } else {
                    checkStatus = false
                }
            }
            if (checkStatus) {
                setIsLoading(true);
                try {
                    const formData = new FormData()
                    formData.append("city_id_lable", isAllCity ? "All" : "Specify City");
                    formData.append("service_provider_id", serviceProviderCity?.service_provider_id);
                    formData.append("affiliation_services_id", serviceProviderCity?.affiliation_services_id);
                    serviceProviderCity?.pincode_id && formData.append("block_pincode_list", serviceProviderCity?.pincode_id.replace(/,$/, ''));
                    !isAllCity && formData.append("city_id_list", JSON.stringify(serviceProviderCity?.city_name));
                    params.id && formData.append("id", params.id);
                    addServiceProviderCity(formData).then(() => { toast.success(`Service Provider City ${params?.id ? "Update Successfuly" : "Add Successfuly"}`); { params?.id ? navigate(`/service-provider-city/view/${params.id}`) : navigate("/service-provider-city") } })
                } catch (error) {
                    console.log(error)
                } finally {
                    setIsLoading(false);
                }
            }

        }
        // }
    }
    return (
        <>
            <Layout sidebar={true}>
                {/* {isLoading && <div className="loader" ></div>} */}
                <div className="page-heading">
                    <h3>Service Provider City {params.id ? "Edit" : "Add"}</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/service-provider-city">Service Provider City</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Service Provider City {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Row className="g-4">
                        <Col sm={12}>
                            <Form noValidate validated={idValidated} onSubmit={handleSave}>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Label>Services Provider</Form.Label>
                                                <SelectPicker
                                                    cleanable={false}
                                                    data={serviceProvider}
                                                    onChange={(e) => handleChange(e, "service_provider_id")}
                                                    value={serviceProviderCity?.service_provider_id&&serviceProviderCity?.service_provider_id}
                                                    name='service_provider_id'
                                                    block

                                                    className={`my-2 ${(idValidated && !serviceProviderCity?.service_provider_id) && "rs-novalidated"}`}
                                                    placeholder="Select Services Provider"
                                                />
                                                {(idValidated && !serviceProviderCity?.service_provider_id) && <div className='rs-invalid-feedback'>
                                                    Select Services Provider
                                                </div>}
                                            </Col>
                                            <Col md={6}>
                                                <Form.Label>Services</Form.Label>
                                                <SelectPicker
                                                    cleanable={false}
                                                    data={service}
                                                    onChange={(e) => handleChange(e, "affiliation_services_id")}
                                                    value={serviceProviderCity?.affiliation_services_id&&serviceProviderCity?.affiliation_services_id}

                                                    block
                                                    className={`my-2 ${(idValidated && !serviceProviderCity?.affiliation_services_id) && "rs-novalidated"}`}
                                                    placeholder="Select Services"
                                                />
                                                {(idValidated && !serviceProviderCity?.affiliation_services_id) && <div className='rs-invalid-feedback'>
                                                    Select Services
                                                </div>}
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>City Key</Form.Label>
                                                <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "city_key")} className='my-2' />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Pincode Key</Form.Label>
                                                <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "pincode_key")} className='my-2' />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>Language Key</Form.Label>
                                                <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "language_key")} className='my-2' />
                                            </Col>
                                            <Col md={6} className='my-2'>
                                                <Form.Label className='me-2'>City Name : </Form.Label>
                                                <Form.Check inline label="All City" name="cityname" type="radio" id='city1' onChange={(e) => { setIsAllCity(true); }} checked={isAllCity} />
                                                <Form.Check inline label="Specify City" name="cityname" type="radio" id='city2' onChange={(e) => { setIsAllCity(false); setSelectedCitys([]); setValidation({ ...validation, cities: "Please Select City" }) }} checked={!isAllCity} />
                                                {!isAllCity && <Select
                                                    closeMenuOnSelect={false}
                                                    name="city_name"
                                                    isDisabled={isAllCity}
                                                    value={selectedCitys}
                                                    onChange={(e) => handleSelectCitys(e)}
                                                    isClearable={true}
                                                    placeholder="Select City"
                                                    className={`customMulSelect my-2 ${(idValidated && !isAllCity && !selectedCitys.length) && "rs-novalidated"}`}
                                                    classNamePrefix="react-select"
                                                    isMulti
                                                    options={citys}
                                                    required
                                                />}
                                                {(idValidated && !isAllCity && !selectedCitys.length) && <div className='rs-invalid-feedback'>
                                                    Select City
                                                </div>}
                                            </Col>
                                            {!isAllCity &&
                                                <Col md={6} className='my-2'>
                                                    <Form.Label>Block Pincode List</Form.Label>
                                                    <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "pincode_id")} value={serviceProviderCity.pincode_id} className='my-2' />
                                                </Col>
                                            }
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="text-end">
                                        <Button variant="primary" className="me-3" type='submit'>Save</Button>
                                        <Link to='/service-provider-city' className="btn btn-secondary">Cancel</Link>
                                    </Card.Footer>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    )
}

export default ServiceProviderCityAdd