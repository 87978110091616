import React, { useEffect, useRef, useState } from "react";
import { Form, Card, Table, Button, Row, Col } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from "rsuite";
import Fancybox from "../../Component/FancyBox";
import { API } from "../../App";
import Cookies from "js-cookie";

const data = [
	{ label: "Bike", value: 1 },
	{ label: "Car", value: 2 },
	{ label: "Truck", value: 3 },
	{ label: "Helicopter", value: 4 },
	{ label: "Plane", value: 5 },
	{ label: "Ship", value: 6 },
]
const Recommended = () => {
	const token = Cookies.get("fmljwt");
	const fileInputRef = useRef(null);

	const [isLoading, setIsLoading] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState(1)
	const [selectedIndex, setSelectedIndex] = useState("")
	const [selectedImage, setSelectedImage] = useState({})
	const [recommendedVehicle, setRecommendedVehicle] = useState([])


	useEffect(() => {
		selectedCategory && getRecommendedVehicle(selectedCategory)
	}, [selectedCategory])

	const handleImageClick = (index) => {
		setSelectedIndex(index)
		fileInputRef.current.click();
	};
	console.log('selectedImage', selectedImage)

	const handleFileChange = (event) => {
		setSelectedImage({ ...selectedImage, [selectedIndex]: event.target.files[0] });
		const file = event.target.files[0]
		// setRecommendedVehicle((prev) => {
		// 	const updatedRecommendedVehicleNew = [...prev.recommendedVehicleNew];
		// 	updatedRecommendedVehicleNew[selectedIndex] = {
		// 		...updatedRecommendedVehicleNew[selectedIndex],
		// 		home_image: file
		// 	};
		// 	return {
		// 		...prev,
		// 		recommendedVehicleNew: updatedRecommendedVehicleNew
		// 	};
		// });

		// Optionally, you can reset the input value to allow selecting the same file again
		event.target.value = null;
	};


	/**get Recommended Vehicle */
	const getRecommendedVehicle = async (category) => {
		setIsLoading(true);
		try {
			const Form = new FormData()
			Form.append("categoryId", category)
			const response = await API.post("get-recommended-vehicle", Form, { headers: { authorization: `${token}` } })
			setRecommendedVehicle(response?.data?.data);
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false);
		}
	}
	/**Update Recommended Vehicle */
	const updateRecommendedVehicle = async (newId, image, oldId) => {
		setIsLoading(true);
		try {
			const Form = new FormData()
			Form.append("vehicleInfoId", newId)
			Form.append("replaceVehicleInfoId", oldId)
			Form.append("homeImage", image)
			const response = await API.post("update-recommended-vehicle", Form, { headers: { authorization: `${token}` } })
			if (response?.data?.status) {
				setSelectedImage({})
				setRecommendedVehicle(response?.data?.data);
				getRecommendedVehicle(selectedCategory)
			}
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false);
		}
	}
	return (
		<Layout sidebar={true}>
			<div className="page-heading">
				<h3 className="my-1">Recommended Vehicle</h3>
				<div className="page-heading-right">
					<SelectPicker
						cleanable={false}
						onChange={(e) => setSelectedCategory(e)}
						data={data}
						value={selectedCategory}
						className="wv-200 my-1 ms-3"
						placeholder="Select Category"
						placement="bottomEnd"
					/>
				</div>
			</div>
			<div className="page-content">
				<Card>
					<Card.Body>
						<Row className="gx-5">
							<Col xs={6} className="replace-arrow">
								<Table bordered responsive>
									<thead>
										<tr>
											<th width="7%" className='text-center'>No</th>
											<th width="68%">Name</th>
											<th width="25%" className='text-center'>Image</th>
										</tr>
									</thead>
									<tbody>
										{recommendedVehicle?.recommendedVehicleOld?.length > 0 && recommendedVehicle?.recommendedVehicleOld?.map((item, index) => {
											return (<tr key={index}>
												<td className='text-center'>{index + 1}</td>
												<td>{item?.model_name}</td>
												<td className='text-center'>
													<Fancybox>
														<a href={item?.home_image} data-fancybox="gallery">
															<img src={item?.home_image ? item?.home_image : "./avatar/1.jpg"} onError={(e) => {
																e.target.src = "./avatar/1.jpg";
															}} height="80" alt={item?.model_name} className="rounded-4" />
														</a>
													</Fancybox>
												</td>
											</tr>)
										})}

									</tbody>
								</Table>
							</Col>
							<Col xs={6}>
								<Table bordered responsive>
									<thead>
										<tr>
											<th width="60%">Name</th>
											<th width="25%" className='text-center'>Image</th>
											<th width="15%" className='text-center'>Action</th>
										</tr>
									</thead>
									<tbody>
										{recommendedVehicle?.recommendedVehicleNew?.length > 0 && recommendedVehicle?.recommendedVehicleNew?.map((item, index) => {
											return (<tr key={index}>
												<td>{item?.model_name}</td>
												<td className='text-center'>
													<Fancybox>
														<a href={item?.home_image} data-fancybox="gallery">
															<img src={item?.home_image ? item?.home_image : "./avatar/1.jpg"} onError={(e) => {
																e.target.src = "./avatar/1.jpg";
															}} height="80" alt={item?.model_name} className="rounded-4" />
														</a>
													</Fancybox>
												</td>
												<td className='text-center'>
													{/* <label htmlFor="myInput">Enter something:</label>
													<input
														type="file"
														id="myInput"
														hidden
														onChange={(e)=>{console.log('e.target.fiels[0', e.target.files[0]);handleFileChange(e,index)}}
													/> */}
													<input type="file" ref={fileInputRef} onChange={(e) => handleFileChange(e)} hidden />

													{selectedImage[index] ? <Button variant="primary px-3" size="sm" onClick={() => updateRecommendedVehicle(item.id,selectedImage[index] , recommendedVehicle?.recommendedVehicleOld[index]?.id)}>Save</Button>
														: <Button variant="primary px-3" size="sm" onClick={() => handleImageClick(index)}>Choose</Button>}
												</td>
											</tr>)
										})}
									</tbody>
								</Table>
							</Col>
						</Row>

					</Card.Body>
				</Card>
			</div>
		</Layout>
	)
}

export default Recommended