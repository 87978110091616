import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

const AppContext = ({ children }) => {
    const [myValue, setMyValue] = useState('Default Value');
    const [userPermission, setUserPermission] = useState(JSON.parse(localStorage.getItem("permissions"))||[]);
    const [token, setToken] = useState( Cookies.get("fmljwt"));
  const contextValue = {
    value: myValue,
    setValue: setMyValue,
  };
  const [classActive, setClassActive] = useState({
    drp1: false,
    drp2: false,
    drp3: false,
    drp4: false,
    drp5: false,
    drp6: false,
});
  return (
    <AuthContext.Provider value={{classActive, setClassActive,userPermission,token,setUserPermission,setToken}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AppContext