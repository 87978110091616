import React from "react";
import { Link } from "react-router-dom";

const PageNotFound=()=>{
    return(
    <>
        <div className="error">
            <div className="no-found-page">
                <img src="../../not-found/404.svg" alt="404" />
                <h1>Oops, Page not found!</h1>
                <p>You can go back home.</p>
                <Link to="/" className="btn btn-primary">Back To Home</Link>
            </div>
        </div>
    </>
    )
}

export default PageNotFound;